@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$max: map-get($breakpoints, $name);
	@return if($max and $max > 0, $max - .02, null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@mixin media($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin media-min($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
	  	@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin media-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);
  
	@if ($min != null and $max != null) {
	  @media (min-width: $min) and (max-width: $max) {
		@content;
	  }
	} @else {
		@content;
	}
}
