/* ------- vacancies -------- */
.p-vacancies {
  ol {
    margin: 1.5rem 0 4rem;
    padding-left: 2rem;

    @include media(tab-l) {
      margin-bottom: 3rem;
    }
  }

  &__item {
    margin-bottom: 2rem;
    max-width: 78rem;

    @include media-min(tab-l) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .vacancy-item__link {
      @include media(mob) {
        padding-left: 1rem;
      }
    }
  }

  &__tabs-menu {
    margin-bottom: 3rem;
    margin-right: 4rem;

    @include media-min(tab-l) {
      margin-bottom: 30px;
      margin-right: 40px;
    }
  }

  &__title-department {
    margin: 4rem 0 2rem;
    font-weight: 600;
    text-transform: uppercase;

    @include media-min(tab-l) {
      margin: 40px 0 20px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__heading {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: flex-start;
    gap: 2rem 4rem;
    margin-bottom: 4rem;

    @include media-min(tab-l) {
      margin-bottom: 40px;
    }

    h2, h3 {
      margin: 0;
      font-weight: 700;
      font-size: var(--title-size-sm);
    }

    @include media(tab-l) {
      margin-bottom: 3rem;
    }

    @include media(mob) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__category {
    padding: 0.7rem 1.8rem;
    width: fit-content;
    border-radius: $radius;
    font-weight: 700;
    font-size: var(--text-size-xs);
    color: $white;

    &:not([class*="u-style"]) {
      background: $accent;
    }

    @include media(tab-l) {
      margin-right: calc((100vw - var(--container-sm-max-width)) * -0.5);
    }

    @include media(mob) {
      order: -1;
      margin-right: 0;
    }
  }

  &__description {
    margin-bottom: 4rem;

    @include media-min(tab-l) {
      margin-bottom: 40px;
    }

    @include media(tab-l) {
      margin-bottom: 3rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__respond {
    margin-top: 6rem;

    @include media-min(tab-l) {
      margin-top: 60px;
    }

    @include media(mob) {
      margin-top: 4rem;
    }
  }

  &__respond-button {
    @include media(mob) {
      width: 100%;
    }
  }

  &__form-row {
    display: grid;
    gap: 2rem;

    @include grid-columns(2);

    @include media(mob) {
      gap: 1rem;

      @include grid-columns(1);
    }
  }

  &__form-vacancy {
    .choices__button {
      display: none;
    }

    .choices.is-changed {
      .choices__list--single {
        padding-right: 0;
      }
    }
  }

  &__form-footer {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: flex-start;
    gap: 2rem 4rem;
    margin-top: 3.5rem;

    @include media-min(tab-l) {
      gap: 20px 30px;
      margin-top: 35px;
    }

    @include media-between(mob, tab-xs) {
      grid-template-columns: minmax(0, 1fr);
    }

    @include media(mob-m) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__form-button-wrapper {
    position: relative;
    display: block;
    margin-top: 0;
    justify-content: flex-start;
    width: 20rem;

    @include media(mob-m) {
      width: 100%;
    }
  }

  &__form-button {
    @include media(mob-m) {
      width: 100%;
    }
  }

  &__form-agreement {
    display: flex;
    align-items: center;
    max-width: 28rem;
    min-height: var(--button-height);
  }

  &__form-code {
    @include media(mob) {
      padding-bottom: 1rem;
    }
  }

  &__form-success {
    position: absolute;
    left: 50%;
    margin-top: 1rem;
    font-size: 1.2rem;
    white-space: nowrap;
    transform: translate(-50%, 0);
  }
}

