/* ------- spitz -------- */
.p-spitz {

  &__row {
    display: grid;
    gap: 2rem;
    grid-template-columns: minmax(0, 1fr) max-content;

    @include media-min(tab-l) {
      gap: 20px;
    }

    @include media(mob) {
      gap: 0;

      @include grid-columns(1);
    }
  }

  &__description {
    h2 {
      @include media-between(mob, tab-l) {
        margin-bottom: 2rem;
      }
    }
  }

  &__image-wrapper {
    width: 38rem;

    @include media-min(tab-l) {
      width: 380px;
    }

    @include media(tab-l) {
      width: 34rem;
      margin-right: calc((100vw - var(--container-sm-max-width)) * -0.5);
    }

    @include media(mob) {
      display: none;
    }
  }

  &__image-box {
    position: relative;
    overflow: hidden;
    padding-top: 142.1052%;
    border-radius: $radius;
    background: var(--color-light);

  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $radius;
  }

  &__image-mobile {
    margin-bottom: 3rem;

    @include media-min(mob) {
      display: none;
    }
  }
}
