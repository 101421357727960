/* ------- Photos Slider -------- */
.photos-slider {
  border-radius: $radius;
  overflow: hidden;

  &__item {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(var(--color-primary-rgb), 0.5);
      transform: translateZ(0);
      transition: $duration * 1.5 opacity;
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      .photos-slider__item-icon {
        fill-opacity: 1;
      }
    }

    &.swiper-slide {
      transform: none;
    }
  }

  &__item-inner {
    position: relative;
    padding-top: 58.62%;
    min-height: 27rem;

    @include media-min(tab-l) {
      min-height: 270px;
    }
  }

  &__item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 4rem;
    height: 4rem;
    fill: $white;
    fill-opacity: 0;
    transition: $duration * 1.5 fill-opacity;
    transform: translate3d(-50%, -50%, 0);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateZ(0);
  }

  &__prev,
  &__next {
    top: auto;
    bottom: 2rem;
    left: auto;
    margin: 0;
    border-radius: 0.3rem;
    width: 3rem;
    height: 3rem;
    transform: none;
    background: $white;

    @include media-min(tab-l) {
      width: 30px;
      height: 30px;
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: $base-color;

      @include media-min(tab-l) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__prev {
    right: 7rem;

    @include media-min(tab-l) {
      right: 70px;
    }

    @include media(mob) {
      right: auto;
      left: 50%;
      margin-left: -4rem;
    }
  }

  &__next {
    right: 2rem;

    @include media(mob) {
      right: auto;
      left: 50%;
      margin-left: 1rem;
    }
  }
}
