/* ------- pagination -------- */
.pagination {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;

  &__counter {
    position: relative;
    top: 1px;
    font-family: $font-sofia;
    font-size: calc(var(--text-size-sm) + 0.1rem);
    text-align: center;
  }

  &__counter-current {
    color: var(--color-base-40);
  }

  &__counter-total {
    &::before {
      content: "/";
      margin: 0 1rem;
    }
  }

  &__nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    font-family: $font-sofia;
    text-decoration: none;
    color: var(--color-base-80);
    transition: $duration color, $duration background;

    &::after {
      content: "";
      position: absolute;
      left: -1rem;
      bottom: -1rem;
      top: -1rem;
      right: -1rem;
    }

    &:hover {
      color: $second;
    }

    &.is-disabled {
      pointer-events: none;
      cursor: default;
      color: var(--color-base-40);
    }

    &_prev {
      .pagination {
        &__nav-icon {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__nav-icon {
    width: 1.6rem;
    height: 1.6rem;
    fill: currentColor;
    stroke: currentColor;
  }
}
