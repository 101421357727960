/* ------- Not found -------- */
.p-not-found {
  position: relative;
  display: flex;
  padding: calc(var(--header-bottom-offset) + 5.5rem) 0 5.5rem;
  height: 100%;
  min-height: 40rem;

  @include media(tab-l) {
    padding-top: 5.5rem;
  }

  @include media(mob) {
    height: 100%;
    min-height: 31.5rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $radius $radius 0 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $accent 0%, rgba(0, 85, 163, 0) 100%);

    @include media(mob) {
      left: 50%;
      min-width: 60rem;
      transform: translate(-50%);
    }
  }

  &__bg-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: $radius $radius 0 0;
  }

  &__bg-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;

    @include media(mob) {
      min-height: 45.6rem;
      object-position: 57% top;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    min-height: 100%;
  }

  &__content {
    @include media(mob) {
      width: 100%;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: var(--title-size-lg);
    color: $white;

    @include media(mob) {
      max-width: 14.5rem;
      margin-bottom: 1rem;
      font-size: var(--title-size-sm);
    }
  }

  &__text {
    font-weight: 600;
    color: $white;

    @include media(mob) {
      max-width: 21rem;
      font-size: var(--text-size-xs);
    }
  }

  &__button-wrapper {
    margin-top: 6rem;

    @include media(mob) {
      margin-top: 3rem;
    }
  }

  &__button {
    @include media(mob) {
      width: 100%;
    }
  }
}

.page-404 {
  display: flex;
  padding-bottom: 0;

  .main-content {
    min-height: 100%;
    width: 100%;
    padding-bottom: 0;
    padding-top: 0;
  }
}
