/* ------- tabs -------- */
@keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tabs-menu {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  @include media(mob) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    @include media-min(tab-l) {
      margin: 0 40px 10px 0;
    }

    &:last-child {
      margin-right: 0;
    }

    margin: 0 4rem 1rem 0;
    padding: 0.7rem 1.8rem;
    border-radius: $radius;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: var(--text-size-xs);
    color: var(--color-base-80);
    transition: $duration color, $duration background;
    cursor: pointer;

    &:hover {
      color: $base-color;
    }
    &:not([class*="u-style-"].is-active) {
      background: var(--color-light);
    }

    &.is-active {
      color: $white;
    }

    @include media(mob) {
      margin-right: 0;
    }
  }
}

.tabs-pane {
  display: none;
  animation: fadeEffect 0.6s linear;

  &.is-active {
    display: block;
  }
}
