/* ------- vanillajs datepicker ------- */
@import '../../../node_modules/vanillajs-datepicker/dist/css/datepicker';

.datepicker-view {
  width: 4rem * 7;
  max-width: 100vw;
}

.datepicker-dropdown{
  z-index: 101;
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0.3rem 0.3rem 1rem rgba($black, 0.1);
  padding: 0;
  border: none;
  border-radius: $radius;
  box-shadow: 0 0 10px rgba(34, 60, 80, 0.3);
  background: $white;
}

.datepicker-controls .button {
  height: 2.8em;
  font-size: 0.9em;
  transition: $duration color, $duration background;
}

.datepicker-controls .prev-button,
.datepicker-controls .next-button {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  transition: $duration color;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: currentColor;
  }

  &:hover {
    color: $second;
  }
}
.datepicker-controls .prev-button {
  svg {
      transform: rotate(180deg);
  }
}

.datepicker-header .datepicker-controls .button {
  font-weight: 500;
}

.datepicker .days-of-week {
  margin-bottom: 1rem;
}

.datepicker .dow {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
}

.datepicker-header {
  margin-bottom: 0.5rem;
}

.datepicker-header .datepicker-controls {
  padding-bottom: 2px;
}

.datepicker-cell {
  height: 4rem;
  font-size: calc(var(--text-size-sm));
  line-height: 4rem;
  border-radius: var(--radius);
  transition: $duration color, $duration background;

  &:not(.disabled):hover {
    color: var(--color-base);
    background: var(--color-light);
  }

  &:not(.month) {
    font-family: "Sofia Sans", "Arial", sans-serif;
    font-size: calc(var(--text-size-sm) + 0.1rem);
  }

  &.selected {
    color: $white;
    background: $accent;

    &, &:hover {
      font-weight: 400;
      color: $white;
      background: $accent;
    }
  }
}

.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: none;
}

