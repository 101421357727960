/* ------- Base ------- */
*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	position: relative;
	height: 100%;
	font-size: 62.5%;
	background: $base-background;
}

body {
	// width: 1440px;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	font-size: $base-font-size;
	line-height: $base-line-height;
	color: $base-color;
}

p {
	margin: 0 0 1.5rem;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color: $link;
	transition: color $duration;
}

img {
	max-width: 100%;
}

hr {
	margin: 2rem 0;
	border: none;
	border-bottom: 1px solid $grey;
	height: 0;
}

table {
	width: 100%;
	border-collapse: collapse;
	text-align: center;

	th, td {
		padding: 1rem;
		border-style: solid;
		border-width: 0 1px 1px 0;
		border-color: white;
	}

	th {
		background: #ddd;
	}

	td {
		background: #f0f0f0;
	}
}

button {
	appearance: none;
	border: none;
	border-radius: 0;
	padding: 0;
	box-shadow: none;
	font-family: inherit;
	font-size: inherit;
	color: $primary;
	background-color: transparent;
	cursor: pointer;
	user-select: none;
}

svg {
	display: block;
	transition: $duration fill, $duration stroke;
}

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	min-width: $min-width;
	min-height: 100vh;
}

.main {
	flex: 1 0 auto;
	width: 100%;
	margin-top: calc(var(--header-bottom-offset) * -1);
  padding-bottom: calc($section-space * 0.5);

  @include media-min(tab-l) {
    padding-bottom: 40px;
  }

	@include media(tab-l) {
		margin-top: 0;
	}
}

.container {
	margin: 0 auto;
	padding: 0 $gutters;
	width: 100%;
	max-width: calc($container + $gutters * 2);

	&_lg {
		max-width: calc($container-lg + $gutters * 2);
	}

	&_sm {
    @include media(tab-l) {
      max-width: var(--container-sm-max-width);
    }

    @include media(mob) {
      max-width: none;
    }
	}
}

.section {
	padding-top: calc($section-space * 0.5);
	padding-bottom: calc($section-space * 0.5);

  @include media-min(tab-l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.main-content {
  padding: calc($section-space * 0.5) 0;

  @include media-min(tab-l) {
    padding: 40px 0;
  }

  @include media(tab-l) {
    padding-bottom: calc(8rem - $section-space * 0.5);
  }

  @include media(mob) {
    padding-bottom: calc(6rem - $section-space * 0.5);
  }
}

::selection {
	color: $white;
	background: $accent;
}

a, button, select {
	&:focus-visible {
		outline: 0.2rem solid $accent;
		outline-offset: 0.3rem;
	}
}
