/* ------- Form ------- */
.form {
	&__grid {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		gap: 2rem;

    @include media(mob) {
      gap: 1.2rem;
    }
	}

  &__field {
    &_attach {
      padding: 1rem 0;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &_date {
      .form__field-row {
        display: grid;
        gap: 2rem;

        @include media(mob) {
          gap: 1.2rem;
        }

        @include grid-columns(2);
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 6rem;

    @include media(mob) {
      margin-top: 3rem;
    }
  }

  &__code {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: center;
    gap: 1rem 2.5rem;

    @include media-between(mob, tab-xs) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__code-title {
    font-weight: 600;
  }

  &__code-box {
    width: 27.5rem;
    height: var(--input-height);
    border-radius: $radius;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $radius;
    }

    @include media(mob-m) {
      width: 15.9rem;
    }
  }

  &__addition {
    margin-top: 5px;
    margin-left: 21px;
  }
}
