@import "mixins.scss";
@import "variables.scss";

/* Для всех элементов задаем цвет границ, чтобы не менять у каждого элемента в частности */
* {
  @include media-min(tab-l) {
    @include wcag(border-color, $borderColorsWcag, true);
  }
}

html:not([data-color="default"]) {
  @include media-min(tab-l) {
    a:not([disabled]):not([aria-disabled="true"]),
    [tabindex]:not([tabindex="-1"]),
    button:not([disabled]):not([aria-disabled="true"]) {
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }

    body {
      line-height: $defaultLineHeight;
    }

    [aria-disabled="true"],
    [disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

html[data-color="white"] {
  @include media-min(tab-l) {
    input,
    select,
    textarea,
    .choices__inner {
      background-color: #fff;
      color: #555;
    }

    a,
    img,
    [tabindex] {
      &:focus,
      &:target {
        outline: 2px solid #0000ff;
        background-color: #fff02a;
        color: #111;

        svg {
          fill: #111;
        }
      }
    }

    input:not([type="checkbox"]),
    select,
    textarea {
      &:focus,
      &:target {
        /* Focus стили будут important */
        outline: 2px solid #0000ff !important;
        background-color: #fff02a !important;
        color: #111 !important;
      }
    }

    a:not([disabled]):not([aria-disabled="true"]):not(.btn),
    [tabindex]:not([tabindex="-1"]),
    .header__version,
    .header__language-dropdown {
      &:hover {
        outline: 2px solid #0000ff;
        color: #555;
      }
    }

    .footer {
      background: var(--color-light) !important;
      color: black;
    }

    .footer__registry-image {
      svg {
        path {
          fill: black;
        }
      }
    }

    .footer__menu-link,
    .footer__contacts-phone,
    .footer__contacts-email,
    .footer__registry-content,
    .footer__registry-logo-text {
      color: black;
    }

    .footer__logo-light-image {
      display: none;
    }

    .footer__logo-dark-image {
      display: block !important;
    }

    .header.is-fixed .header__inner {
      background: var(--color-light) !important;
      color: black;
    }

    .menu__link {
      color: black !important;

      &:hover {
        color: var(--color-base-80) !important;
      }
    }

    .header__logo-text {
      fill: black;
    }

    .ui-button {
      background-color: #d6d6d6;
      color: black;

      &:hover,
      &:active {
        background: white;
      }

      &_style_outline {
        background-color: #f6f4f4;
        border: 1px solid #d6d6d6;
        color: black;

        &:hover,
        &:active {
          background: white;
        }
      }
    }

    .last-news__more {
      padding-bottom: 3px;
    }

    .footer__subscribe-submit {
      &:hover {
        outline: 2px solid #0000ff;
      }
    }

    .heading {
      background-color: #f5fbff;
    }

    .breadcrumbs__list {
      align-items: center;
      display: flex;
      justify-content: end;
      min-height: 20px;
    }

    .heading__title,
    .breadcrumbs__link {
      color: black;
    }

    .breadcrumbs__item {
      color: black;
      overflow: visible;

      &::before {
        background-color: black;
      }

      &:last-child {
        color: grey;

        &::before {
          background-color: grey;
        }
      }
    }

    .menu__submenu {
      li::before {
        bottom: 1px;
      }
    }

    .ui-attach__input {
      color: #FFFFFF;
    }

    .footer__copyright-studio {
      color: black;
    }
  }
}

html[data-color="black"] {
  @include media-min(tab-l) {
    input,
    select,
    textarea,
    .choices__inner,
    .choices__list--dropdown {
      background-color: #1d1d1d !important;
      color: #ccc;

      &:disabled {
        background-color: #1d1d1d !important;
        color: #ccc;
      }
    }

    input:not([type="checkbox"]),
    select,
    textarea,
    img {
      &:focus,
      &:target {
        /* Focus стили будут important */
        outline: 2px solid #ccc !important;
        background-color: #fff02a !important;
        color: #111 !important;

        svg {
          fill: #111;
        }
      }

      &::placeholder {
        color: #fff;
      }
    }

    .choices__item {
      &:hover,
      &:focus-visible {
        color: #fff02a !important;
      }
    }

    main {
      background-color: #1d1d1d !important;
    }

    .main-content {
      color: #FFFFFF;
    }

    .section-title {
      color: #FFFFFF;
    }

    .ui-attach__button-text {
      color: #fff;
    }

    .p-projects__item-link {
      &:hover {
        background-color: #fff02a !important;
        color: #111;
      }
    }

    .ui-checkbox__text {
      color: #fff;
    }

    .ui-checkbox__checked:before {
      background-color: #fff;
    }

    .ui-attach__icon,
    .ui-attach__file-icon {
      path {
        fill: #FFFFFF;
      }
    }

    @include disabled {
      color: #fff;
      background-color: #111;
    }

    @include error {
      background: #111;
    }

    a:not([disabled]):not([aria-disabled="true"]):not(.btn),
    [tabindex]:not([tabindex="-1"]),
    .header__version,
    .header__language-dropdown {
      &:hover {
        outline: 2px solid #ccc;
      }
    }

    .wrapper {
      background-color: #1d1d1d;
    }

    .header__logo-light-image {
      display: block;
    }

    .header__logo-dark-image {
      display: none;
    }

    .header__contacts-phone,
    .header__contacts-email,
    .header__version-text,
    .header__language-current {
      color: #FFFFFF;
    }

    .header__version-icon {
      fill: var(--color-base-20);

      + .header__version-icon {
        background: var(--color-base-20);
      }
    }

    .header__language-wrapper {
      svg {
        fill: #aaa !important;
      }

      &:hover {
        svg {
          fill: white !important;
        }
      }
    }

    .header__menu {
      background: var(--color-base);
    }

    .menu__link {
      color: var(--color-base-20);

      &:hover {
        color: white;
      }
    }

    .location-minerals__box {
      background-color: #3b3b40;
      color: #FFFFFF;
    }

    .article-item__link {
      &:hover,
      &:focus-visible {
        .article-item {
          &__content {
            background: #1d1d1d;
          }
        }
      }
    }

    .article-item__content,
    .vacancy-item__link {
      background-color: #3b3b40;

      div {
        color: #FFFFFF;
      }
    }

    .vacancy-item__link {
      &:hover,
      &:focus-visible {
        background: #1d1d1d;
      }
    }

    .ui-button {
      background-color: #4f4c4c;

      &:hover,
      &:active {
        background: black;
      }

      &_style_outline {
        background-color: #4f4c4c;
        border: 1px solid white;
        color: white;

        &:hover,
        &:active {
          background: black;
        }
      }
    }

    .last-news__more {
      padding-bottom: 3px;
    }

    .p-vacancies__form-button,
    .footer__subscribe-submit {
      &:hover {
        outline: 2px solid white;
      }
    }

    .heading {
      background-color: #041017;
    }

    .breadcrumbs__list {
      align-items: center;
      display: flex;
      justify-content: end;
      min-height: 20px;
    }

    .breadcrumbs__item {
      overflow: visible;

      &:last-child {
        color: grey;

        &::before {
          background-color: grey;
        }
      }
    }

    .article-item__gallery-title {
      color: #FFFFFF;
    }

    .menu__submenu {
      background: #1d1d1d;

      a {
        color: white;

        &:hover {
          color: var(--color-base-60);
        }
      }
    }

    .datepicker-picker,
    .datepicker-header {
      background: #3e4042;

      button {
        background: #3e4042;
        color: #FFFFFF;
      }
    }

    .datepicker-cell.focused:not(.selected) {
      background-color: #677581;
    }

    .ui-attach__input {
      color: #1d1d1d;
    }
  }
}

/* При выключенных картинках */
html[data-images="off"] {
  @include media-min(tab-l) {
    img {
      visibility: hidden;
      transition: unset;
    }

    *,
    *:before,
    *:after {
      background-image: none !important; /* !important на случай inline стилей в верстке */
    }

    .photos-slider__gallery-item {
      visibility: hidden;
    }

    .field-box__input {
      &:required {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_141_12438)'%3E%3Cpath d='M15.6753 12.0187L10.017 1.60193C9.58989 0.882988 8.83564 0.453613 7.99939 0.453613C7.16314 0.453613 6.40889 0.882988 5.98183 1.60193C5.97861 1.60743 5.97589 1.61293 5.97267 1.61843L0.333169 12.0023C-0.103049 12.7364 -0.111299 13.618 0.310701 14.3603C0.73367 15.1031 1.49567 15.5462 2.35026 15.5462H13.6173C14.4719 15.5462 15.2652 15.1031 15.6881 14.3603C16.1101 13.618 16.1019 12.7363 15.6753 12.0187ZM7.06095 5.22327C7.06095 4.70496 7.48111 4.28483 7.99939 4.28483C8.5177 4.28483 8.93783 4.70499 8.93783 5.22327V8.97705C8.93783 9.4953 8.51767 9.91549 7.99939 9.91549C7.48111 9.91549 7.06095 9.49527 7.06095 8.97705V5.22327ZM7.99939 13.6693C7.22317 13.6693 6.5917 13.0379 6.5917 12.2616C6.5917 11.4854 7.22314 10.854 7.99939 10.854C8.77561 10.854 9.40704 11.4854 9.40704 12.2616C9.40708 13.0378 8.77564 13.6693 7.99939 13.6693Z' fill='%236396C5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_141_12438'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") !important;
      }
    }

    .article-item__link {
      outline: none !important;
    }

    .p-new-single__slider,
    .p-project-single__slider {
      visibility: hidden;
    }

    .p-not-found {
      background: rgba(var(--color-primary-rgb));
    }

    .p-not-found__container::before {
      content: "Страница не найдена";
      position: absolute;
      top: 20%;
      font-weight: 700;
      font-size: var(--title-size-lg);
      margin-bottom: 2rem;
      color: var(--background-color);
    }

    .p-not-found__container::after {
      content: "К сожалению запрашиваемая страница не была найдена";
      position: absolute;
      top: calc(20% + 85px);
      font-weight: 600;
      color: var(--background-color);
    }

    .p-not-found__title,
    .p-not-found__text,
    .location-minerals__item:before,
    .location-minerals__map-circles,
    .location-minerals__item-line,
    .location-minerals__map {
      visibility: hidden;
    }

    .article-item__content {
      border-top-width: unset;
    }

    .footer__copyright-studio_15web {
      &::before {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370.9 254.9' fill='%23d60d35'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M132.3 0h95.6L95.5 254.9H0L132.3 0m143 0h95.6L238.5 254.9H143L275.3 0'/%3E%3C/svg%3E") no-repeat 50%/contain !important;
      }
    }
  }
}

/* При увеличенном кернинге */
html[data-kerning="big"] {
  @include media-min(tab-l) {
    letter-spacing: 0.1rem;

    select,
    input,
    button,
    textarea {
      letter-spacing: inherit;
    }

    .header__container,
    .header__left {
      column-gap: 15px;

      @media (min-width: 1200px) {
        column-gap: 25px;
      }

      @media (min-width: 1220px) {
        column-gap: 60px;
      }
    }

    .header__contacts {
      column-gap: 15px;

      @media (min-width: 1220px) {
        column-gap: 50px;
      }
    }

    .header__social {
      column-gap: 12px;
    }

    .menu__list {
      column-gap: 20px;
    }

    .footer__menu {
      gap: 20px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
    }

    .footer__section_third {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 30px;
      align-items: center;
    }

    .footer__description {
      max-width: 275px;
    }

    .footer__registry {
      margin-left: unset;
    }
  }
}

/* Конфиги для font-size */
@each $key, $value in (normal: 0.625rem, big: 0.750rem, large: 1rem) {
  @include media-min(tab-l) {
    html[data-fontsize="#{$key}"] {
      font-size: $value;
    }

    html[data-fontsize="big"] {
      .header {
        height: 153px;
      }

      .header__social {
        column-gap: 12px;
      }

      .menu__list {
        column-gap: 20px;
        min-height: 83px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 10px 0;
        align-items: center;
      }

      .menu__link {
        height: 40px;
      }

      .footer__menu {
        gap: 20px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
      }

      .footer__section {
        padding: 25px 0;
      }

      .footer__section_third {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px;
        align-items: center;
      }

      .footer__description {
        max-width: 275px;
      }

      .footer__registry {
        align-items: center;
        margin-left: unset;
      }

      .home-section__container {
        align-items: center;
      }

      .home-section__slide-content {
        padding: 70px 60px 100px 0;
      }

      .home-section__slide-title {
        max-height: 120px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .home-section__slide-text {
        max-height: 189px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .home-section__slider-next {
        @media (max-width: 1550px) {
          right: 30px;
        }

        right: calc(50% - 756px);
      }

      .home-section__slider-prev {
        @media (max-width: 1550px) {
          left: 30px;
        }

        left: calc(50% - 756px);
      }

      .location-minerals__item-title {
        margin-bottom: 5px;
      }

      .location-minerals__item-text {
        -webkit-line-clamp: 3
      }

      .tours__slide-title {
        font-size: 48px;
      }

      .last-news {
        padding-bottom: 48px;
      }

      .field-box__input:required {
        background-position: right 2rem center;
      }

      .form__code-box {
        align-items: center;
        display: flex;
        justify-content: right;
        max-width: 250px;

        & > img {
          height: 55px;
          width: auto;
        }
      }

      .ui-button {
        padding: 0 20px;
      }

      .ui-attach__icon {
        width: 30px;
        height: 30px;
      }

      .p-vacancies__form-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .p-vacancies__form-button {
        width: 100%;
      }

      .last-news__slider,
      .current-vacancies__slider {
        width: 100%;
      }

      .last-news__item {
        min-width: 280px;
        width: calc(25% - 15px);
      }

      .current-vacancies__item {
        min-width: 380px;
        width: calc((100% - 40px) / 3);
      }

      .header__container {
        grid-template-rows: 92px auto;

        @media (max-width: 1305px) {
          gap: 0;
        }
      }

      .header__right {
        @media (min-width: 1025px) and (max-width: 1306px) {
          display: none;
        }
      }

      .header__left {
        width: 100%;

        @media (min-width: 1025px) and (max-width: 1306px) {
          column-gap: 30px;
          grid-template-columns: minmax(0, 1.5fr) max-content max-content;
        }

        @media (min-width: 1307px) {
          column-gap: 40px;
          display: flex;
        }

        @media (min-width: 1420px) {
          column-gap: 65px;
        }
      }

      .header__contacts {
        column-gap: 40px;
      }
    }

    html[data-fontsize="large"] {
      .header {
        height: 176px;
      }

      .header__social {
        column-gap: 12px;
      }

      .menu__list {
        column-gap: 20px;
        min-height: 83px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 10px 0;
        align-items: center;
      }

      .menu__link {
        height: 40px;
      }

      .footer__menu {
        gap: 20px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
      }

      .footer__section {
        padding: 30px 0;
      }

      .footer__subscribe {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
      }

      .footer__subscribe-form {
        column-gap: 20px;
      }

      .footer__subscribe-submit {
        width: 220px;
        min-width: unset;
      }

      .footer__section_third {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 30px 20px;
        align-items: center;
      }

      .footer__description {
        max-width: 275px;
      }

      .footer__registry {
        align-items: center;
        margin-left: unset;
      }

      .home-section__container {
        align-items: unset;
      }

      .home-section__slide-content {
        padding: 80px 60px 100px 0;
      }

      .home-section__slide-image-box {
        margin-bottom: 130px;
      }

      .home-section__slide-title {
        font-size: 48px;
      }

      .home-section__slide-title {
        max-height: 120px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .home-section__slide-text {
        max-height: 189px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .home-section__slide-button {
        margin-top: 30px;
      }

      .home-section__slider-next {
        @media (max-width: 2064px) {
          right: 30px;
        }

        right: calc(50% - 1008px);
      }

      .home-section__slider-prev {
        @media (max-width: 2064px) {
          left: 30px;
        }

        left: calc(50% - 1008px);
      }

      .location-minerals__item-title {
        margin-bottom: 5px;
      }

      .location-minerals__item-text {
        -webkit-line-clamp: 2
      }

      .tours__slide-title {
        font-size: 48px;
      }

      .last-news {
        padding-bottom: 64px;
      }

      .field-box__input:required {
        background-position: right 2rem center;
      }

      .form__code-box {
        align-items: center;
        display: flex;
        justify-content: right;
        max-width: 250px;

        & > img {
          height: 55px;
          width: auto;
        }
      }

      .ui-button {
        padding: 0 20px;
      }

      .ui-attach__icon {
        width: 30px;
        height: 30px;
      }

      .p-vacancies__form-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .p-vacancies__form-button {
        width: 100%;
      }

      .form__field_attach {
        padding: 1rem 0;
      }

      .contacts-block__item {
        align-items: flex-end;
      }

      .photos-slider__prev {
        right: 80px;
      }

      .last-news__slider,
      .current-vacancies__slider {
        width: 100%;
      }

      .last-news__item {
        min-width: 280px;
        width: calc(25% - 15px);
      }

      .current-vacancies__item {
        min-width: 380px;
        width: calc((100% - 40px) / 3);
      }

      .header__container {
        grid-template-rows: 92px auto;

        @media (max-width: 1555px) {
          gap: 0;
        }
      }

      .header__right {
        @media (min-width: 1025px) and (max-width: 1555px) {
          display: none;
        }
      }

      .header__left {
        width: 100%;

        @media (min-width: 1025px) and (max-width: 1555px) {
          column-gap: 30px;
          grid-template-columns: minmax(0, 1.5fr) max-content max-content;
        }

        @media (min-width: 1556px) {
          column-gap: 40px;
          display: flex;
        }

        @media (min-width: 1700px) {
          column-gap: 100px;
          grid-template-columns: max-content 1fr 1fr;
        }
      }

      .header__contacts {
        column-gap: 40px;

        @media (min-width: 1700px) {
          column-gap: 75px;
        }
      }
    }
  }
}
