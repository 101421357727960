
// Mixins
@import "./mixins";

// Functions
@import "./functions";

// Variables
@import "./vars";

// Fonts
@import "./fonts";

// Vendor
@import "./vendor";

// Base
@import "./common/base";
@import "./common/utilites";

// Plugins
@import "./plugins/plugins";

// Modules
@import "./modules/icon";
@import "./modules/button";
@import "./modules/input";
@import "./modules/checkbox";
@import "./modules/attach";
@import "./modules/modal";

// Common elements
@import "./common/elements";
@import "./common/form";

// Header
@import "./header/menu";
@import "./header/header";

// Footer
@import "./footer/footer";

// Site blocks
@import "./blocks/fieldBox";
@import "./blocks/breadcrumbs";
@import "./blocks/pagination";
@import "./blocks/heading";
@import "./blocks/tabs";
@import "./blocks/articleItem";
@import "./blocks/vacancyItem";
@import "./blocks/contactsBlock";
@import "./blocks/photosSlider";

// Site sections
@import "./sections/homeSection";
@import "./sections/locationMinerals";
@import "./sections/tours";
@import "./sections/lastNews";
@import "./sections/currentVacancies";

// Pages
@import "./pages/about";
@import "./pages/projects";
@import "./pages/projectSingle";
@import "./pages/spitz";
@import "./pages/news";
@import "./pages/newSingle";
@import "./pages/vacancies";
@import "./pages/notFound";

// Version for the visually impaired
@import "./wcag/wcag-panel/wcag-panel";
@import "./wcag/wcag-config/variables";
@import "./wcag/wcag-config/mixins";
@import "./wcag/wcag-config/wcag-config";
