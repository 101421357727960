/* ------- UI - Icon ------- */
svg,
.ui-icon {
	display: block;
	transition: $duration fill, $duration stroke;
}

.ui-icon {
	width: 2.4rem;
	height: 2.4rem;
	fill: currentColor;
}