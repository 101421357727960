/* ------- Menu ------- */

.menu {
	&__nav {
		@include media(tab-l) {
			padding-right: 6rem;
			padding-left: 11rem;
		}

		@include media(tab-sm) {
			padding-left: 3rem;
			// padding-right: 2rem;
		}

		@include media(mob) {
			flex-grow: 1;
			padding-left: $gutters;
			padding-right: $gutters;
		}
	}

	&__list {
		display: grid;
		grid-auto-flow: column;
		gap: 4.4%;
		list-style: none;
		margin: 0;
		padding: 0;

		@include media(netbook) {
			gap: 3.8%;
		}

		@include media(tab-l) {
			gap: 3rem;
			grid-template-rows: repeat(3, 1fr);

			@include grid-columns(3);

		}

		@include media(mob) {
			grid-template-rows: auto;
			grid-auto-flow: row;
			gap: 2rem;

			@include grid-columns(1);
		}
	}

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 5.2rem;
		text-decoration: none;
		text-align: center;
		font-size: 1.2rem;
		font-weight: 500;
		color: $base-color;
		transition-property: background, color, border-color;
    text-wrap: nowrap;

		@include media(tab-l) {
			display: block;
			width: fit-content;
			height: auto;
			padding-left: 2rem;
			text-align: left;
			font-weight: 300;
			font-size: 2.4rem;
		}

		@include media(tab-sm) {
			font-size: 2rem;
		}

		@include media(mob) {
			font-size: 1.6rem;
			font-weight: 400;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0.4rem;
			border-radius: $radius;
			transition: $duration background;

      @include media-min(tab-l) {
        height: 4px;
      }

			@include media(tab-l) {
				top: 50%;
				bottom: auto;
				margin-top: -2rem;
				width: 0.4rem;
				height: 4rem;
			}

			@include media(mob) {
				height: 2.4rem;
				margin-top: -1.2rem;
			}
		}

		&:hover {
			color: var(--color-base-80);

			&::before {
				background: var(--color-primary-60);
			}
		}
	}

	&__item {
		&_active {
			.menu__link {
				color: var(--color-base-80);

				&::before {
					background: $second;
				}
			}
		}

		&_dropdown {
			position: relative;

			&.is-active {
				.menu__submenu {
					visibility: visible;
					opacity: 1;
          z-index: 10;
				}

				.menu__link {
					color: var(--color-base-60);

					&::before {
						background: var(--color-primary-60);
					}
				}
			}

			@include media-min(tab-l) {
				&.is-active {
					.menu__submenu {
						transform: translate(0, 0);
					}
				}
			}

			@include media(tab-l) {
				&.is-active {
					.menu__submenu {
						visibility: visible;
						opacity: 1;
						transform: translate(0, 0);
					}
				}
			}
		}
	}

	&__submenu {
		position: absolute;
		top: 100%;
		left: 0;
		width: 21rem;
		margin-top: 0.4rem;
		margin-left: -1.2rem;
		list-style: none;
		padding: 0.4rem 0;
		box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6);
		border-radius: $radius;
		background: $white;
		opacity: 0;
		visibility: hidden;
		transition: $duration opacity, $duration visibility, $duration transform;
		will-change: transform, opacity, visibility;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: -0.4rem;
			width: 100%;
			height: 0.4rem;

			@include media(tab-l) {
				display: none;
			}
		}

		@include media(tab-l) {
			box-shadow: none;
			display: none;
		}

		a {
			display: block;
			padding: 1.2rem;
			font-weight: 500;
			font-size: var(--text-size-xs);
			color: var(--color-base-60);
			text-decoration: none;

			&:hover {
				color: $base-color;
			}
		}

		li {
			position: relative;
			padding-bottom: 1px;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 1.2rem;
				right: 1.2rem;
				height: 1px;
				background: var(--color-light);
			}

			&:last-child {
				padding-bottom: 0;

				&::before {
					display: none;
				}
			}
		}
	}

	&__backdrop {
		display: none;

		@include media(tab-l) {
			position: fixed;
			left: 0;
			right: 0;
			top: $header-height;
			height: calc(var(--vh, 1vh) * 100 - $header-height);
			display: block;
			background: $backdrop;
			opacity: 0;
			transition: $duration opacity;
			pointer-events: none;
			will-change: opacity;
			backdrop-filter: blur(0.4rem);


			@include active {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	&__container {
		@include media(tab-l) {
			padding: 6rem 3.4rem 4rem 0;
			width: 100%;
			max-height: 100%;
			overflow: auto;
		}

		@include media(mob) {
			padding-right: $gutters;
		}

		@include media(mob) {
			display: flex;
			min-height: 100%;
			flex-direction: column;
			padding-top: 3rem;
			padding-bottom: 3rem;
			padding-right: 0;
			border-top: 2px solid var(--color-light);
		}
	}

	&__popup {
		@include media(tab-l) {
			position: fixed;
			top: 0;
			left: 0;
			padding: $header-height 0 0;
			max-height: calc(var(--vh, 1vh) * 100);
			display: none;
			width: 100%;
			max-width: 100%;
			overflow: hidden;
			background: $white;
			transform: translate3d(0, -1rem, 0);
			transition: $duration transform, $duration opacity;
			opacity: 0;
			will-change: transform, opacity;

			@include active {
				transform: translate3d(0, 0, 0);
				opacity: 1;
			}

			@include visible {
				display: flex;
			}
		}

		@include media(mob) {
			height: calc(var(--vh, 1vh) * 100);
			transform: translate3d(0, 0, 0);
		}
	}

	&__popup-content {
		position: relative;
		display: grid;
		align-items: center;
		grid-template-columns: 33vw minmax(0, 1fr) max-content;
		justify-content: flex-end;
		margin-top: 7rem;
		padding-left: $gutters;
		gap: 4rem;

		&::before {
			content: "";
			display: block;

			@include media(mob) {
				display: none;
			}
		}

		@media (max-width: 900px) {
			grid-template-columns: 30vw minmax(0, 1fr) max-content;
		}

		@include media(mob) {
			grid-template-columns: minmax(0, 1fr);
			gap: 2rem;
			margin-top: 3rem;
			padding-right: $gutters;
			padding-top: 3rem;
			border-top: 2px solid var(--color-light);
		}

		@include media-min(tab-l) {
			display: none;
		}
	}

	&__burger {
		$burger-width: 2.4rem;
		$burger-height: 2.4rem;
		$burger-line-width: 1.4rem;
		$burger-line-last-width: 1rem;
		$burger-line-height: 0.2rem;
		$burger-line-offset: 0.3rem;

		position: relative;
		z-index: 1;
		display: none;
		border-radius: $radius;
		width: $burger-width;
		height: $burger-height;
		background: $base-color;

		span {
			position: absolute;
			top: 50%;
			margin-left: calc($burger-line-width * (-0.5));
			margin-top: calc($burger-line-height * (-0.5));
			border-radius: $radius;
			width: $burger-line-width;
			height: $burger-line-height;
			background: $white;
			transition: $duration transform, $duration width, $duration opacity;
			will-change: transform, width, opacity;

			&:first-child {
				transform: translateY(($burger-line-offset + $burger-line-height) * (-1));
			}

			&:last-child {
				width: $burger-line-last-width;
				transform: translateY($burger-line-offset + $burger-line-height);
			}
		}

		@include active() {
			span {
				&:first-child {
					transform:scale(1.15) rotate(45deg) ;
				}

				&:last-child {
					opacity: 0;
				}

				&:nth-child(2) {
					transform: scale(1.15) rotate(-45deg);
				}
			}
		}

		@include media(tab-l) {
			display: block;
		}
	}
}

.is-menu-active {
	@include media(tab-l) {
		overflow: hidden;
		padding-right: var(--lock-padding-right);
		touch-action: none;
	}
}
