/* ------- new-single -------- */
.p-new-single {

  &__row {
    display: grid;
    gap: 2rem;

    @include media-min(tab-l) {
      gap: 20px;
    }

    @include grid-columns(2);

    @include media(tab-l) {
      grid-template-columns: minmax(0, 51.5fr) minmax(0, 48.5fr);
    }

    @include media(mob) {
      @include grid-columns(1);
    }
  }

  &__head {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    gap: 4rem;
    min-height: 3rem;
    align-items: center;
    margin-bottom: 2rem;

    @include media-min(tab-l) {
      margin-bottom: 20px;
    }

    @include media(tab-l) {
      margin-right: calc((100vw - var(--container-sm-max-width)) * -0.5);
    }

    @include media(mob) {
      margin-right: 0;
    }
  }

  &__head-left {
    display: grid;
    justify-content: flex-start;
    grid-auto-flow: column;
    gap: 6rem;

    @include media(mob) {
      gap: 3rem;
    }

  }

  &__back-icon {
    fill: currentColor;
  }

  &__date {
    font-family: $font-sofia;
    font-size: calc(var(--text-size-sm) + 0.1rem);
    color: var(--color-base-60);
  }

  &__views {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 0.5rem;
    font-family: $font-sofia;
    font-size: calc(var(--text-size-sm) + 0.1rem);
    color: var(--color-base-80);
  }

  &__views-icon {
    position: relative;
    top: -1px;
    width: 1.4rem;
    height: 1.4rem;
    fill: currentColor;
  }

  &__back {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 1.2rem;
    text-decoration: none;
    color: $accent;

    &:hover {
      color: $second;
    }
  }

  &__back-icon {
    position: relative;
    top: 1px;
    width: 1.4rem;
    height: 1.4rem;
    transform: rotate(180deg);

    @include media-min(tab-l) {
      width: 20px;
      height: 20px;
    }
  }

  &__description {
    h2 {
      @include media(tab-l) {
        margin-bottom: 2rem;
      }
    }
  }

  &__slider-wrapper {
    @include media(tab-l) {
      margin-right: calc((100vw - var(--container-sm-max-width)) * -0.5);
    }

    @include media(mob) {
      display: none;
    }
  }

  &__slider-mobile {
    margin-bottom: 2rem;

    @include media-min(mob) {
      display: none;
    }
  }
}

