/* Переменные со значениями для разных цветовых схем */

/* Границы блоков */
$borderColorsWcag: (white: #aaa, black: #ccc, blue: #cc0, beige: #444, brown: #aad469);

/* "основной" фон */
$firstBackgroundColorsWcag: (white: #fff, black: #1d1d1d, blue: #06066b, beige: #f7f3d6, brown: #352518);

/* "Дополнительный" фон, чтобы выделять блоки на фоне основного */
$secondBackgroundColorsWcag: (white: #f5f5f5, black: #000, blue: #0c0c4e, beige: #fbf2af, brown: #2b1d11);

/* Ссылки */
$linkColorsWcag: (white: #222, black: #fff, blue: #ff0, beige: #111, brown: #9bff00);
$linkInverseColorsWcag: $secondBackgroundColorsWcag;

/* Текст */
$textColorsWcag: (white: #555, black: #ccc, blue: #cc0, beige: #444, brown: #aad469);

/* Подчеркивания ссылок */
$linkBorderColorsWcag: (white: #0000ff, black: #ccc, blue: #cc0, beige: #444, brown: #aad469);

/* Инвертированный текст */
$textInvertColorsWcag: $firstBackgroundColorsWcag;

/* Инвертированный фон */
$invertBackgroundColorsWcag: $textColorsWcag;

/* Фон, цвет текста и outline границы элементов в фокусе */
$focusBackgroundColorsWcag: (white: #fff02a, black: #fff02a, blue: #fff02a, beige: #fff02a, brown: #fff02a);
$focusTextColorsWcag: (white: #111, black: #111, blue: #111, beige: #111, brown: #111);
$focusOutlinesWcag: (white: 2px solid #0000ff, black: 2px solid #ccc, blue: 2px solid #cc0, beige: 2px solid #444, brown: 2px solid #aad469);

/* Фон и цвет текста элементов при наведении */
$hoverBackgroundColorsWcag: $focusBackgroundColorsWcag;
$hoverTextColorsWcag: $focusTextColorsWcag;
$hoverOutlinesWcag: $focusOutlinesWcag;


$defaultFontSize: 16;
$defaultLineHeight: 1.25;

$transparentPixel: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
