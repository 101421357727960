/* ------- Plugin styles ------- */
@import "./swiper";
@import "./lightgallery";
@import "./choices";
@import "./just-validate";
@import "./datepicker";

/* Lozad */
.lazy{
	opacity: 0;
	transition: $duration-lazy;

	&[data-loaded="true"],
	&.loaded{
		opacity: 1;
	}
}
