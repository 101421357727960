/*WCAG-PANEL*/
.wcag-panel {
  height: 0;
  transition: height 400ms ease;
  position: relative;
  overflow: hidden;
  font-size: 17px;
  line-height: 24px;
  background-color: var(--color-primary);
  box-sizing: border-box;
  z-index: 999;
  letter-spacing: normal;
  color: #000;

  @include media(tab-l) {
    display: none;
    height: 0;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }

  &__wrapper {
    margin: 0 auto;
    padding: 30px 15px;
    display: flex;
  }

  /* Строки с отступом между собой, на случай, если их будет несколько */
  &__row {
    position: relative;
    margin: auto;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  /* Колонки с управляющими элементами */
  &__col {
    display: inline-block;
    vertical-align: bottom;
    padding: 0 11px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &_cont-link {
      vertical-align: bottom;
      padding-bottom: 8px;
    }
  }

  &__digital {
    font-family: $font-sofia;
  }

  /* Элемент-контейнер переключаетелей */
  &__switcher {
    font-size: 0;
    overflow: hidden;
    border: 1px solid var(--color-base) !important;
    border-radius: 3px;
    width: fit-content;
  }

  &__switcher-label {
    vertical-align: baseline;
    margin-bottom: 12px;
    display: inline-block;
    color: #FFFFFF;
    font-weight: 500;
  }

  &__switcher-btn {
    padding: 0 10px;
    height: 38px;
    font-weight: normal;
    margin-bottom: 0;

    background-color: #fff;
    cursor: pointer;

    font-size: 15px;
    line-height: 38px;
    border: 0;
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      border-right: 1px solid var(--color-base);
    }

    &:focus {
      background: #000 !important;
      color: #fff !important;
      box-shadow: inset 0 0 0 2px #ffa;
    }
  }

  &__flag-button {
    font-size: 15px;
    line-height: 38px;
    text-align: center;
    display: inline-block;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 7px;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    /* "Скрываем" их таким образом, а не {display: none} чтобы focus с клавиатуры работал */
    position: absolute;
    left: -99999px;

    &:checked + .wcag-panel__switcher-btn {
      color: white;
      background-color: var(--color-base);
    }

    &:focus + .wcag-panel__switcher-btn {
      color: white;
      background: var(--color-base);
      box-shadow: inset 0 0 0 2px #ffa;
      outline: 0;
    }
  }
}

.visible-wcag-panel {
  height: 135px;
}

/*FLAG-BUTTON*/

.flag-button {
  position: relative;
  width: 31px;
  height: 38px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--color-base) !important;

  input:focus + & {
    box-shadow: inset 0 0 0 2px #ffa;
    background: var(--color-base);
    color: white;
  }

  /* Красные точки на выбранных кнопках */
  &:before,
  &:after {
    display: none;
    content: '';
    position: absolute;
    top: -3px;
    left: 11px;

    width: 8px;
    height: 8px;

    background: #fe0d0d;
    border-radius: 50%;

    input:checked + & {
      display: block;
    }
  }

  &:after {
    bottom: -3px;
    top: auto;
  }

  &_default {
    color: #fff;
    border-color: #fff !important;
  }

  &_white {
    background: #fff;
  }

  &_black {
    color: #fff;
    background: var(--color-base);
  }
}
