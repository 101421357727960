/* ------- location-minerals -------- */
.location-minerals {
  padding-top: 0;
  padding-bottom: calc(var(--section-space-sm) * 0.5);

  @include media-min(tab-l) {
    padding-bottom: 30px;
  }

  @include media(mob) {
    padding-bottom: calc(var(--section-space) * 0.5);
  }

  &__row {
    display: grid;
    grid-template-columns: 39.5rem minmax(0, 1fr);
    gap: 4rem 10.5rem;

    @include media-min(tab-l) {
      grid-template-columns: 395px minmax(0, 1fr);
      gap: 40px 105px;
    }

    @include media(tab-l) {
      column-gap: 8.5rem;
    }

    @include media(mob) {
      grid-template-columns: minmax(0, 1fr);
      gap: 2rem;
    }
  }

  &__box {
    position: relative;
    z-index: 1;
    padding: 4.3rem 4rem 0;
    border-radius: $radius;
    background: $white;

    @include media-min(tab-l) {
      padding: 43px 40px 30px;
    }

    @include media(tab-l) {
      margin-top: 0;
      padding: 4rem 0 0;
    }
  }

  &__column {
  }

  &__map {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 395px;
    height: 395px;
    max-width: 395px;
    max-height: 395px;

    @include media(mob) {
      margin-left: auto;
      margin-right: auto;
      width: calc(100vw - $gutters * 2);
      height: calc(100vw - $gutters * 2);
    }
  }

  &__map-box {
    width: 75.9495%;
    height: 90.1266%;
  }

  &__map-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__map-circles {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px dashed var(--color-base-40);
    border-radius: 50%;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border: 1px dashed var(--color-base-40);
      border-radius: 50%;
    }

    &::before {
      width: 63.04%;
      height: 63.04%;
    }

    &::after {
      width: 27.59%;
      height: 27.59%;
    }
  }

  &__pointers {
  }

  &__pointer {
    position: absolute;
    z-index: 1;
    width: 2.6rem;
    height: 3rem;
    color: $second;

    @include media-min(tab-l) {
      width: 26px;
      height: 30px;
    }

    @include media(mob) {
      width: m-adaptive(20);
      height: m-adaptive(23);
    }

    &_1 {
      left: 35%;
      top: 47%;
    }

    &_2 {
      top: 57.5%;
      left: 32%;
    }

    &_3 {
      top: 59.6%;
      left: 27.5%;
    }
  }

  &__pointer-icon {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &__items {

  }

  &__item {
    position: relative;
    margin-bottom: 3.8rem;
    height: 12.2rem;

    @include media-min(tab-l) {
      margin-bottom: 38px;
      height: 122px;
    }

    @include media(mob) {
      margin-bottom: 3rem;
      height: auto;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0.9rem;
      left: -2.8rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background: $second;

      @include media-min(tab-l) {
        top: 9px;
        left: -28px;
        width: 10px;
        height: 10px;
      }

      @include media(mob) {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
      height: auto;
    }

    &:nth-child(1) {
      .location-minerals__item-line {
        width: 31.6rem;
        height: 21.5rem;

        @include media-min(tab-l) {
          width: 316px;
          height: 215px;
        }

        @include media(tab-l) {
          width: 29.6rem;
        }

        &::before {
          width: 10rem;
          right: 0;
          border-top-width: 1px;
          border-left-width: 1px;

          @include media-min(tab-l) {
            width: 10px;
          }

          @include media(tab-l) {
            width: 8rem;
          }
        }

        &::after {
          bottom: 0;
          right: 10rem;
          left: 0;
          border-bottom-width: 1px;

          @include media-min(tab-l) {
            right: 10px;
          }

          @include media(tab-l) {
            right: 8rem;
          }
        }
      }
    }

    &:nth-child(2) {
      .location-minerals__item-line {
        width: 32.9rem;
        height: 9.7rem;

        @include media-min(tab-l) {
          width: 329px;
          height: 97px;
        }

        @include media(tab-l) {
          width: 30.9rem;
        }

        &::before {
          width: 5rem;
          right: 0;
          border-top-width: 1px;
          border-left-width: 1px;

          @include media-min(tab-l) {
            width: 50px;
          }

          @include media(tab-l) {
            width: 3rem;
          }
        }

        &::after {
          bottom: 0;
          right: 5rem;
          left: 0;
          border-bottom-width: 1px;

          @include media-min(tab-l) {
            right: 50px;
          }

          @include media(tab-l) {
            right: 3rem;
          }
        }
      }
    }

    &:last-child {
      .location-minerals__item-text {
        display: block;
        -webkit-line-clamp: inherit;
      }

      .location-minerals__item-line {
        width: 40rem;
        height: 5.6rem;
        transform: translate(0, -100%);

        @include media-min(tab-l) {
          width: 400px;
          height: 56px;
        }

        @include media(tab-l) {
          width: 38rem;
        }

        &::before {
          width: 100%;
          right: 0;
          border-bottom-width: 1px;
          border-left-width: 1px;
        }

        &::after {
          @include media-min(tab-l) {
            width: 35px;
          }

          left: 0;
          width: 3.5rem;
          border-top-width: 1px;
        }
      }
    }
  }

  &__item-line {
    position: absolute;
    top: 0.9rem + 0.45rem;
    right: 100%;
    margin-right: 2.25rem;

    @include media-min(tab-l) {
      top: 13.5px;
      margin-right: 22.5px;
    }

    @include media(mob) {
      display: none;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      border: 0 solid $second;
    }

    &::before {
      top: 0;
      height: 100%;
    }
  }

  &__item-title {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: var(--title-size-sm);

    @include media-min(tab-l) {
      margin-bottom: 20px;
    }

    @include media(tab-l) {
      margin-bottom: 1rem;
    }

    @include media(mob) {
      font-size: var(--text-size);
      font-weight: 600;
    }
  }

  &__item-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media-min(tab-l) {
      margin-bottom: 10px;
    }

    @include media(mob) {
      font-size: var(--text-size-sm);
    }
  }

  &__item-more {
    font-size: var(--text-size-sm);
    color: $accent;

    &:hover {
      color: $second;
    }
  }
}
