/* ------- vacancy-item -------- */
.vacancy-item {
  font-size: var(--text-size);

  &__link {
    @include media-min(tab-l) {
      padding: 20px 20px 20px 16px;
    }

    display: block;
    min-height: 100%;
    padding: 2rem 2rem 2rem 1.6rem;
    border: 1px solid var(--color-base-20);
    border-radius: $radius;
    text-decoration: none;
    background: $white;
    transition: $duration border-color, $duration background;

    &:hover,
    &:focus-visible {
      border-color: var(--color-primary-light-80);
      background: var(--color-light-second);
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + .vacancy-item__title {
      @include media-min(tab-l) {
        margin-top: 22px;
      }

      margin-top: 2.2rem;
    }
  }

  &__category {
    @include media-min(tab-l) {
      padding: 7px 18px;
      max-width: 260px;
    }

    padding: 0.7rem 1.8rem;
    border-radius: $radius;
    font-weight: 700;
    font-size: var(--text-size-xs);
    color: $white;
    background: $accent;
  }

  &__arrow {
    @include media-min(tab-l) {
      width: 20px;
      height: 20px;
      margin-left: 30px;
    }

    width: 2rem;
    height: 2rem;
    margin-left: 3rem;
    fill: $accent;
  }

  &__title {
    font-weight: 600;
    color: var(--color-base);
  }

  &__response-button {
    @include media-min(tab-l) {
      margin-left: 20px;
    }

    align-self: flex-end;
    margin-left: 2rem;
    text-decoration: underline;
    font-size: var(--text-size-sm);
    color: $second;
    transition: $duration color;

    &:hover {
      color: var(--color-second-dark);
    }
  }
}
