/* ------- UI - Checkbox ------- */
.ui-checkbox {
	position: relative;
	display: grid;
	grid-template-columns: max-content minmax(0, 1fr);
	gap: 1.2rem;
	width: fit-content;
	font-size: var(--text-size-xs);
  font-weight: normal;
  color: $base-color;
	cursor: pointer;
  user-select: none;

	&:hover {
		.ui-checkbox__checked {
			border-color: $accent;

      &::before {
        background-color: $accent;
      }
		}
	}

	&__checked {
    @include media-min(tab-l) {
      border: 3px solid;
      border-radius: 5px;
    }

		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.3rem solid;
    border-radius: 0.5rem;
		width: 2.8rem;
		height: 2.8rem;
		transition: $duration border-color, $duration background;

    &::before {
      content: "";
      display: block;
      width: 1.6rem;
      height: 1.2rem;
      mask-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3389 1.06043C14.7531 0.474647 13.8033 0.474647 13.2176 1.06043L6.44977 7.82822L2.56066 3.93911C1.97487 3.35333 1.02513 3.35333 0.43934 3.93911C-0.146447 4.5249 -0.146447 5.47465 0.43934 6.06043L5.38909 11.0102C5.97487 11.596 6.92462 11.596 7.51041 11.0102C7.51866 11.0019 7.52679 10.9936 7.53481 10.9852C7.54352 10.9769 7.55215 10.9685 7.5607 10.9599L15.3389 3.18175C15.9247 2.59597 15.9247 1.64622 15.3389 1.06043Z' fill='%232A2623'/%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;
      mask-size: 100%;
      background-color: $base-color;
      opacity: 0;
      transition: $duration opacity, $duration background-color;
    }
	}

	&__focused {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

  &__text {
    min-height: 2.8rem;
    display: flex;
    align-items: center;
    transition: $duration color;
  }

	&__input {
		position: absolute;
		left: 0;
		top: 0;
		width: 0;
		height: 0;
		opacity: 0;

		&:focus-visible {
			~ .ui-checkbox__focused {
				outline: 2px solid $accent;
				outline-offset: 2px;
			}
		}

		&:checked {
			~ .ui-checkbox__checked {
        &::before {
          opacity: 1;
        }
			}
		}

		&:disabled {
			~ .ui-checkbox__checked,
			~ .checkbox__text {
				opacity: 0.4;
				cursor: default;
			}
		}

    &.is-error {
      ~ .ui-checkbox__checked {
        border-color: $error;
      }

      ~ .ui-checkbox__text {
        color: $error;
      }
    }
	}

  + .just-validate-error-label {
    display: none;
  }
}
