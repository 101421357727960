/* ------- Header ------- */
.header {
	position: relative;
	z-index: 100;
	flex-shrink: 0;
	width: 100%;
	height: $header-height;

  @include media-min(tab-l) {
    height: 132px;
  }

	&__inner {
		@include media(tab-l) {
			height: $header-height;
		}
	}

	&__container {
		display: grid;
		grid-template-columns: minmax(0, 1fr) auto;
		grid-template-rows: var(--header-top-height) auto;
		grid-template-areas:
		"left right"
		"menu menu";
		align-items: center;
		height: 100%;
    column-gap: 4rem;

		@include media(tab-l) {
			grid-template-columns: minmax(0, 1fr) auto;
			grid-template-areas:
			"left menu";
			column-gap: 4rem;
		}

    @media (min-width: 1306px) and (max-width: 1400px) {
      column-gap: 30px !important;
    }

		@include media(mob) {
			column-gap: 3rem;
		}
	}

  &__line {
    @include media-min(tab-l) {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      overflow-x: visible;
      align-items: center;
    }
  }

	&__left {
		display: grid;
		grid-template-columns: $logo-width max-content max-content;
		grid-area: left;
		align-items: center;
		height: 100%;
		grid-template-areas:
			"logo contacts social";
		gap: 1rem 6rem;

    @include media-min(tab-l) {
      gap: 10px 60px;
    }

		@include media(pc) {
			column-gap: 3rem;
			grid-template-columns: minmax(0, 1.5fr) minmax(0, 2fr) max-content;
		}

		@include media(tab-l) {
			column-gap: 4rem;
			justify-content: flex-end;
			grid-template-columns: 33vw  minmax(0, 1fr) max-content;
		}

		@media (max-width: 900px) {
			grid-template-columns: 30vw minmax(0, 1fr) max-content;
		}

		@include media(tab-sm) {
			// grid-template-columns: minmax(0, 1fr) auto max-content;
		}

		@include media(mob) {
			grid-template-columns: minmax(0, 1fr) auto;
			grid-template-areas: "logo contacts";
		}
	}

	&__right {
		display: grid;
		grid-template-columns: max-content max-content;
		grid-template-areas:
			"version language";
		justify-content: flex-end;
		grid-area: right;
		gap: 1rem 6rem;
		align-items: center;
		height: 100%;

    @include media-min(tab-l) {
      gap: 10px 60px;
    }

		@include media(pc) {
			column-gap: 2rem;
			// grid-template-areas:
			// "language";
			// grid-template-columns: max-content;
		}

		@include media(tab-l) {
			display: none;
		}
	}

	&__logo {
		position: relative;
		z-index: 1;
		grid-area: logo;
    width: max-content;
	}

	&__logo-box {
		display: block;
	}

	&__logo-image {
		display: block;
		width: $logo-width;
		height: $logo-height;

    @include media-min(tab-l) {
      width: 240px;
      height: 59px;
    }
	}

  &__logo-light-image {
    display: none;
  }

	&__logo-text {
		display: none;
		fill: $white;
	}

	&__contacts {
		position: relative;
		z-index: 1;
		display: grid;
		grid-area: contacts;
		grid-auto-flow: column;
		justify-content: flex-start;
		gap: 5rem;

		@include media(pc) {
			column-gap: 3rem;
		}

		@include media(mob-m) {
			display: none;
		}
	}

	&__contacts-block {
		&_second {
			@include media(tab-l) {
				display: none;
			}
		}
	}

	&__contacts-block-inner {
		display: grid;
		grid-template-columns: auto auto;
		align-items: center;
		justify-content: flex-start;
		gap: 1rem;
		width: fit-content;

		&:hover {
			.header__contacts-icon-box {
				color: $white;
				background: var(--color-base-20);
			}
		}
	}

	&__contacts-icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: $radius;
		color: var(--color-base-20);
		transition: $duration background, $duration color;
	}

	&__contacts-icon {
		width: $icon-size;
		height: $icon-size;
		fill: currentColor;

    @include media-min(tab-l) {
      max-width: 30px;
      max-height: 30px;
    }
	}

	&__contacts-info {
		display: grid;
		justify-content: flex-start;
		gap: 0.4rem;

		@include media(tab-l) {
			gap: 2rem;
			grid-auto-flow: column;
      grid-template-columns: 11.5rem auto;
		}
	}

	&__contacts-phone,
	&__contacts-email {
		width: fit-content;
		font-weight: 700;
		font-size: var(--text-size-xs);
		text-decoration: none;
		color: var(--color-base-80);
		white-space: nowrap;

		&:hover {
			color: $base-color;
		}
	}

	&__contacts-phone {
    font-family: $font-sofia;
    font-size: calc(var(--text-size-xs) + 0.1rem);
    line-height: 1;
    border-top: 1px solid transparent;
		border-bottom: 1px solid;
	}

	&__contacts-email {

		text-decoration: none;
	}

	&__social-wrapper {
		position: relative;
		z-index: 1;
		grid-area: social;

		@include media(mob) {
			display: none;
		}
	}

	&__social {
		display: grid;
		grid-auto-flow: column;
		justify-content: flex-start;
		gap: 1.4rem;

		@include media(mob) {
			order: 1;
		}
	}

	&__social-link {
		border-radius: $radius;
		width: $icon-size;
		height: $icon-size;
		background: var(--color-light);
		transition: $duration color, $duration background;

    @include media-min(tab-l) {
      max-width: 30px;
      max-height: 30px;
    }

		&_vk {
			color: var(--color-vk);

			&:hover {
				background: var(--color-vk);
			}
		}

		&_ok {
			color: var(--color-ok);

			&:hover {
				background: var(--color-ok);
			}
		}

		&_telegram {
			color: var(--color-telegram);

			&:hover {
				background: var(--color-telegram);
			}
		}

		&:hover {
			color: $white;
		}
	}

	&__social-icon {
		width: $icon-size;
		height: $icon-size;
		fill: currentColor;

    @include media-min(tab-l) {
      max-width: 30px;
      max-height: 30px;
    }
	}

	&__version {
		grid-area: version;
		margin-left: auto;
		width: 13.8rem;
    cursor: pointer;

		@include media(tab-l) {
			display: none;
		}
	}

	&__version-button {
		position: relative;
		display: block;
		padding-left: 3.4rem;
		font-weight: 700;
		line-height: var(--text-line-height);
		font-size: var(--text-size-sm);
		text-decoration: none;
		color: var(--color-base-80);

		&:hover {
			color: $base-color;

			.header__version-icon {
				opacity: 0;

				+ .header__version-icon {
					opacity: 1;
				}
			}
		}
	}

	&__version-icon {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: calc($icon-size * -0.5);
		border-radius: $radius;
		width: $icon-size;
		height: $icon-size;
		fill: $base-color;
		transition: $duration opacity, $duration fill, $duration fill;

    @include media-min(tab-l) {
      max-width: 30px;
      max-height: 30px;
    }

		+ .header__version-icon {
			opacity: 0;
			fill: $white;
			background: $base-color;
		}
	}

	&__version-text {
    display: block;
    text-align: start;
	}

	&__language-wrapper {

		grid-area: language;
		margin-left: auto;

		@include media(tab-l) {
			display: none;
		}
	}

	&__language {
		position: relative;
		z-index: 2;
		display: flex;

		@include media(tab-l) {
			order: 2;
		}

		@include media(mob) {
			position: absolute;
			right: $gutters;
			bottom: 0;
			align-items: center;
			height: 2.4rem;

		}
	}

	&__language-dropdown {
		position: relative;

		&:hover {
			.header__language-arrow {
				fill: $base-color;
			}
		}

		&.is-active {
			.header__language-arrow {
				fill: $base-color;
				transform: rotate(180deg);
			}

			.header__language-popup {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__language-button {
		position: relative;
		display: flex;
		align-items: center;
		padding: 1rem 2.7rem 1rem 0;
		width: 8rem;
		font-weight: 500;
		font-size: 1.2rem;
		text-transform: uppercase;
	}

	&__language-arrow {
		position: absolute;
		top: 50%;
		right: 0.4rem;
		margin-top: -0.6rem;
		width: 1.2rem;
		height: 1.2rem;
		fill: var(--color-base-20);
		transition: $duration fill, $duration transform;
	}

	&__language-flag {
		flex: none;
		margin-right: 1rem;
		border-radius: 50%;
		width: 1.6rem;
		height: 1.6rem;
    max-width: 30px;
    max-height: 30px;
	}

	&__language-current {
	}

	&__language-popup {
		position: absolute;
		right: 0;
		margin-top: 0.5rem;
		padding: 0.4rem 0;
		box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6);
		border-radius: $radius;
		width: 9.2rem;
		visibility: hidden;
		opacity: 0;
		background: $white;
		transition: $duration opacity, $duration visibility;

		@include media(tab-l) {
			bottom: 100%;
			margin-top: 0;
		}
	}

	&__language-list {
	}

	&__language-item {
		position: relative;
		display: flex;
		align-items: center;
		padding: 1.2rem;
		margin-bottom: 1px;
		font-weight: 500;
		font-size: var(--text-size-xs);
		line-height: 1;
		text-transform: uppercase;
		text-decoration: none;
		color: var(--color-base-60);

		&::before {
			content: "";
			position: absolute;
			left: 1.2rem;
			right: 1.2rem;
			bottom: 0;
			height: 1px;
			background: var(--color-light);
		}

		&:hover,
		&:focus-visible {
			color: $base-color;
		}

		&:focus-visible {
			outline: none;
		}

		&:last-child {
			margin-bottom: 0;

			&::before {
				display: none;
			}
		}
	}

	&__menu {
		grid-area: menu;
		border-radius: $radius;
		padding: 0 100px;
		background: var(--color-light);

		@include media(netbook) {
			padding-left: 50px;
			padding-right: 50px;
		}

		@include media(pc) {
			padding-left: 30px;
			padding-right: 30px;
		}

		@include media(tab-l) {
			padding: 0;
			margin-left: 0.8rem + 4.8rem;
			background: none;
		}

		@media (max-width: 900px) {
			margin-left: 0;
		}

		@include media(tab-sm) {
			// margin-left: 0;
		}
	}

	&.is-fixed {
		.header {
			&__inner {
				position: fixed;
				z-index: 100;
				left: 0;
				top: 0;
				width: 100%;
				height: fit-content;
        min-height: $header-height-fixed;
				background: $base-color;

        .is-modal-active & {
          padding-right: var(--modal-lock-padding-right);
        }
			}

			&__contacts,
			&__social-wrapper {
				display: none;
			}

			&__right {
				display: none;
			}

			&__left {
				grid-template-columns: auto;
				grid-template-areas: "logo";

			}

			&__container {
				grid-template-columns: max-content minmax(0, 1fr);
				grid-template-areas:
					"left menu";
					grid-template-rows: auto;
				gap: 6rem;

				@include media(pc) {
					gap: 3rem;
				}

				@include media(tab-sm) {
					// padding-left: $gutters;
					// padding-right: $gutters;
				}


			}

			&__logo-image {
				display: none;
			}

			&__logo-text {
				display: block;

				.is-menu-active & {
					@include media(tab-l) {
						fill: $base-color;
					}
				}
			}

			&__menu {
				padding: 0;
				background: none;

				@include media(tab-l) {
					margin-left: auto;
				}

				.menu {
					&__link {
						color: $white;

						.is-menu-active & {
							@include media(tab-l) {
								color: $base-color;
							}
						}
					}

					&__burger {
						@include media(tab-l) {
							background: var(--color-light);

							span {
								background: $base-color;
							}
						}
					}

					&__popup {
						@include media(tab-l) {
							padding-top: $header-height-fixed;
						}
					}
				}
			}
		}

		.menu__list {
			@include media(pc) {
				gap: 2%;
			}

			@include media(tab-l) {
				gap: 3rem;
			}
		}
	}
}
