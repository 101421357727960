.datepicker {
  width: -moz-min-content;
  width: min-content;
}

.datepicker:not(.active) {
  display: none;
}

.datepicker-dropdown {
  position: absolute;
  z-index: 20;
  padding-top: 4px;
}

.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: hsl(0, 0%, 100%);
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.datepicker-main {
  flex: auto;
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0, 0%, 96%);
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0, 0%, 96%);
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-controls {
  display: flex;
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}

.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid gainsboro;
  border-radius: 4px;
  box-shadow: none;
  background-color: hsl(0, 0%, 100%);
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: hsl(0, 0%, 21%);
  font-size: 1rem;
}

.datepicker-controls .button:focus,
.datepicker-controls .button:active {
  outline: none;
}

.datepicker-controls .button:hover {
  border-color: #b8b8b8;
  color: hsl(0, 0%, 21%);
}

.datepicker-controls .button:focus {
  border-color: hsl(217, 71%, 53%);
  color: hsl(0, 0%, 21%);
}

.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.datepicker-controls .button:active {
  border-color: #474747;
  color: hsl(0, 0%, 21%);
}

.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}

.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: bold;
}

.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}

.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}

.datepicker-footer .datepicker-controls .button {
  flex: auto;
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 2px;
  font-size: 0.75rem;
}

.datepicker-controls .view-switch {
  flex: auto;
}

.datepicker-controls .prev-button,
.datepicker-controls .next-button {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  flex: 0 0 14.2857142857%;
}

.datepicker-controls .prev-button.disabled,
.datepicker-controls .next-button.disabled {
  visibility: hidden;
}

.datepicker-view,
.datepicker-grid {
  display: flex;
}

.datepicker-view {
  align-items: stretch;
  width: 15.75rem;
}

.datepicker-grid {
  flex-wrap: wrap;
  flex: auto;
}

.datepicker .days {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.datepicker .days-of-week {
  display: flex;
}

.datepicker .week-numbers {
  display: flex;
  flex-direction: column;
  flex: 0 0 9.6774193548%;
}

.datepicker .weeks {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: auto;
}

.datepicker span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.datepicker .dow {
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.datepicker .week {
  flex: auto;
  color: #b8b8b8;
  font-size: 0.75rem;
}

.datepicker-cell,
.datepicker .days .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-cell {
  height: 2.25rem;
}

.datepicker-cell:not(.day) {
  flex-basis: 25%;
  height: 4.5rem;
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.datepicker-cell.focused:not(.selected) {
  background-color: #e9e9e9;
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: hsl(217, 71%, 53%);
  color: #fff;
  font-weight: 600;
}

.datepicker-cell.disabled {
  color: gainsboro;
}

.datepicker-cell.prev:not(.disabled),
.datepicker-cell.next:not(.disabled) {
  color: hsl(0, 0%, 48%);
}

.datepicker-cell.prev.selected,
.datepicker-cell.next.selected {
  color: #e6e6e6;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: hsl(0, 0%, 96%);
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #efefef;
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e9e9e9;
}

.datepicker-cell.today:not(.selected) {
  background-color: hsl(171, 100%, 41%);
}

.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}

.datepicker-cell.today.focused:not(.selected) {
  background-color: #00ccad;
}

.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #b8b8b8;
  color: #fff;
}

.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #b3b3b3;
}

.datepicker-cell.range-start:not(.range-end) {
  border-radius: 4px 0 0 4px;
}

.datepicker-cell.range-end:not(.range-start) {
  border-radius: 0 4px 4px 0;
}

.datepicker-cell.range {
  border-radius: 0;
  background-color: gainsboro;
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d7d7d7;
}

.datepicker-cell.range.disabled {
  color: #c6c6c6;
}

.datepicker-cell.range.focused {
  background-color: #d1d1d1;
}

.datepicker-input.in-edit {
  border-color: #276bda;
}

.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(39, 107, 218, 0.2);
}