/* ------- lastNews -------- */
.last-news {
  overflow: hidden;

  &__title {
    margin-bottom: var(--section-title-margin);

    @include media-min(tab-l) {
      margin-bottom: 40px;
    }
  }

  &__slider {
    overflow: visible;
    margin: 0;
    width: calc(var(--container-max-width) + (100vw - var(--container-max-width)) * 0.5 - 5rem);

    @include media(netbook) {
      width: auto;
    }

    .swiper-wrapper {
      @include media(mob) {
        display: grid;
        gap: 2rem;
        transform: none !important;
      }
    }
  }

  &__item {
    width: 28rem;
    height: auto;

    @include media-min(tab-l) {
      width: 280px;
    }

    @include media(tab-l) {
      width: 24rem;
    }

    @include media(tab-xs) {
      width: 22rem;
    }

    @include media(mob) {
      width: 100%;
    }
  }

  &__more {
    display: flex;
    justify-content: flex-end;
    margin-bottom: calc($section-space * -0.5);
    margin-top: calc($section-space * 0.5);

    @include media-min(tab-l) {
      margin-top: 40px;
    }

    @include media(tab-l) {
      margin-top: 2rem;
      margin-bottom: -1rem;
    }
  }

  &__more-button {
    @include media(mob) {
      width: 100%;
    }
  }
}
