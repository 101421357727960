/* ------- UI - button ------- */
.ui-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 3rem;
	border-radius: $radius;
	box-shadow: none;
	width: fit-content;
	height: $button-height;
	min-width: 200px;
  max-height: 64px;
	font-weight: 500;
	font-size: var(--text-size-xs);
	line-height: 1.3;
	text-align: center;
	text-decoration: none;
	color: $white;
	background: var(--color-second-white);
	transition: $duration background, $duration color, $duration border-color, $duration box-shadow;
  cursor: pointer;

	&_fill {
		width: 100%;
	}

	&_center {
		margin-right: auto;
		margin-left: auto;
	}

	&:hover {
		background: $second;
  }

	&:active {
		background: var(--color-second-dark);
	}

	&:focus-visible {
		outline-color: $second;
	}

  &_style_outline {
    border: 1px solid currentColor;
    color: var(--color-second-white);
    background: none;

    &:hover {
      color: $second;
      background: none;
    }

    &:active {
      color: var(--color-second-dark);
      background: none;
    }
  }

	@include disabled {
		color: $white;
		background: $disabled-background;
		cursor: default;

		&:hover {
			color: $white;
			background: $disabled-background;
		}
	}
}
