@mixin grid($guttersX: 3rem, $guttersY: $guttersX) {
	display: grid;
	grid-gap: $guttersY $guttersX;
}

@mixin grid-columns($columns: 3) {
	grid-template-columns: repeat($columns, minmax(0, 1fr));
}

@mixin flex($guttersX: 3rem, $guttersY: $guttersX, $wrap: wrap) {
	display: flex;
	flex-wrap: $wrap;
	gap: $guttersY $guttersX;
	margin-right: $guttersX * (-1);
}

@mixin flex-columns($columns: 3, $gutters: 3rem) {
	width: calc(100% / #{$columns} - #{$gutters});
}

@mixin flex-column($width: 50%, $gutters: 3rem) {
	width: calc($width - $gutters);
}