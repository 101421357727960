// Mixins
@import "mixins";

// Functions
@import "functions";

// Base
$base-font-family: "Raleway", "Arial", sans-serif;
$base-font-weight: 400;
$base-font-size: var(--text-size);
$base-line-height: var(--text-line-height);
$base-color: var(--color-base);
$base-background: var(--background-color);

// Fonts
$font-primary: $base-font-family;
$font-sofia: "Sofia Sans", "Arial", sans-serif;

// Font sizes
$font-size-normal: $base-font-size;

// Colors
$black: #000;
$white: #fff;
$grey: grey;
$accent: var(--color-primary);
$second: var(--color-second);
$primary: var(--color-base);
$backdrop: var(--color-backdrop);
$input-border: var(--color-light);
$input-background: var(--color-light);
$placeholder: var(--color-base-80);
$disabled-color: #aaa;
$disabled-background: #aaa;
$error: var(--color-error);
$success: var(--color-success);
$link: var(--color-link);

// Container
$container: var(--container-max-width);
$container-lg: var(--container-lg-max-width);
$min-width: 320px;
$gutters: var(--gutters);
$section-space: var(--section-space);

// Breakpoints
$grid-breakpoints: (
	mob-xxs: 375px,
	mob-xs: 420px,
	mob-sm: 480px,
	mob-m: 576px,
	mob: 768px,
	tab-xs: 900px,
	tab-sm: 960px,
	tab: 992px,
	tab-l: 1025px,
	pc: 1200px,
	netbook: 1340px,
	pc-l: 1440px,
	pc-md: 1600px,
	pc-xl: 1680px,
	pc-xxl: 1920px
);

// Header
$header-height: var(--header-height);
$header-height-fixed: var(--header-height-fixed);

// Logo
$logo-width: var(--logo-width);
$logo-height: var(--logo-height);

// Button
$button-height: var(--button-height);

// Input
$input-height: var(--input-height);
$icon-size: var(--icon-size);

// Animation
$duration: 0.3s;
$duration-lazy: 0.6s;

// Radius
$radius: var(--radius);

:root {
	--background-color: #fff;
	--color-base: #2A2623;
	--color-base-rgb: 42, 38, 35;
	--color-base-80: #55514F;
	--color-base-60: #7F7D7B;
	--color-base-40: #AAA8A7;
	--color-base-20: #D4D4D3;
	--color-primary: #0055A3;
	--color-primary-rgb: 0, 85, 163;
	--color-primary-dark: #004482;
	--color-primary-80: #004482;
	--color-primary-60: #6396C5;
	--color-primary-light-80: #3175B3;
	--color-second: #E63329;
	--color-second-dark: #B82921;
	--color-second-white: #E95A52;
	--color-light: #EAEEF2;
	--color-light-second: #F9FAFB;
	--color-vk: #07F;
	--color-ok: #EB722E;
	--color-telegram: #039BE5;
	--color-trust: #06BAD2;
	--color-barentsburg: #345DC7;
	--color-tourism: #7357C2;
	--color-murmansk: #9B3BAB;
	--color-backdrop: rgb(42 38 35 / 90%);
	--color-error:#E63329;
	--color-success: #0f0;
	--color-link: inherit;
	--container-max-width: 118rem;
	--container-lg-max-width: 126rem;
	--container-sm-max-width: 74rem;
	--gutters: 4rem;
	--section-space: 8rem;
	--section-space-sm: 6rem;
	--section-title-margin: 4rem;
	--header-height: 13.2rem;
	--header-top-height: 8rem;
	--header-height-fixed: 5.2rem;
	--header-bottom-offset: 2.6rem;
	--logo-width: 24rem;
	--logo-height: 5.9rem;
	--radius: 0.4rem;
	--button-height: 4rem;
	--input-height: 6rem;
	--icon-size: 2.4rem;
  --title-size-lg: 4rem;
  --title-size: 3.2rem;
  --title-size-sm: 2.4rem;
	--text-size: 1.6rem;
	--text-line-height: 1.2;
	--text-size-sm: 1.4rem;
	--text-size-xs: 1.2rem;

	@include media(pc) {
		--gutters: 2rem;
	}

	@include media(tab-l) {
		--gutters: 1rem;
		--height: 6.4rem;
		--header-height: 6.4rem;
		--header-top-height: 6.4rem;
		--header-height-fixed: 4rem;
    --section-space: 6rem;
    --section-space-sm: 4rem;
		--logo-width: 18rem;
		--logo-height: 4.7rem;
    --section-title-margin: 2rem;
	}

	@include media(mob) {
		--header-height: 6rem;
		--header-top-height: 6rem;
	}
}
