/* ------- Site elements ------- */
.section-title {
    margin-top: 0;
    font-size: var(--title-size-sm);
    font-weight: 700;

    h1,
    h2,
    h3,
    h4 {
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        color: inherit;
    }
}

.description {
  font-size: var(--text-size-sm);

  h2 {
    margin: 4rem 0;
    font-size: var(--title-size-sm);
    font-weight: 700;

    @include media-min(tab-l) {
      margin: 40px 0;
    }

    @include media(tab-l) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin: 4rem 0 1.2rem;
    font-weight: 600;
    font-size: var(--text-size);

    @include media-min(tab-l) {
      margin: 40px 0 12px;
    }

    @include media(tab-l) {
      margin-top: 3rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    margin: 1rem 0 1.5rem;
    padding-left: 2rem;

    @include media(tab-l) {
      margin-bottom: 1rem;
    }

    > li {
      &::marker {
        font-family: $font-sofia;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 1.5rem 0 4rem;
    padding: 0;
    list-style: none;

    @include media(tab-l) {
      margin-bottom: 3rem;
    }

    > li {
      position: relative;
      margin-bottom: 1.5rem;
      padding-left: 4rem;

      @include media(mob) {
        padding-left: 2rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0.5rem;
        left: 2rem;
        border: 2px solid var(--color-second-white);
        border-radius: 50%;
        width: 0.8rem;
        height: 0.8rem;

        @include media(mob) {
          left: 0;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
