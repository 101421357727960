/* ------- tours -------- */
.tours {
  padding-top: calc(var(--section-space-sm) * 0.5);

  @include media-min(tab-l) {
    padding-top: 30px;
  }

  @include media(mob) {
    padding-top: calc(var(--section-space) * 0.5);
  }

  &__title {
    @include media-min(tab-l) {
      margin-bottom: 40px;
    }

    margin-bottom: var(--section-title-margin);
  }

  &__slider {
    border-radius: $radius;
    overflow: hidden;
    margin-left: calc($gutters * -1);
    margin-right: calc($gutters * -1);
  }

  &__slide {
    position: relative;
    padding: 6.5rem 14rem 4rem;
    height: auto;
    background: $accent;

    @include media-min(tab-l) {
      padding: 65px 140px 40px;
    }

    @include media(tab-l) {
      padding-left: 11rem;
      padding-right: 11rem;
    }

    @include media(tab-xs) {
      padding-left: 9rem;
      padding-right: 9rem;
    }

    @include media(mob) {
      padding: 10rem $gutters 4rem;
    }

    &.swiper-slide {
      transform: none;
    }

    &.swiper-slide-active {
      .tours {
        &__slide-content {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.6s;
        }
      }
    }
  }

  &__slide-image-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;


    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, $accent 0%, rgba(0, 85, 163, 0.00) 100%);
      transform: translateZ(0);
    }
  }

  &__slide-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: soft-light;

    @include media(mob) {

    }
  }

  &__slide-content {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
    "title title"
    "text button";
    gap: 6.2rem 4rem;
    min-height: 100%;
    opacity: 0;
    transition: $duration * 2 opacity;
    transition-delay: 0s;
    will-change:  opacity;

    @include media-min(tab-l) {
      gap: 62px 40px;
    }

    @include media(mob) {
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas:
      "title"
      "text"
      "button";
      gap: 2rem;
    }
  }

  &__slide-title {
    grid-area: title;
    max-width: 48rem;
    font-weight: 700;
    font-size: var(--title-size-lg);
    color: $white;

    @include media-min(tab-l) {
      max-width: 480px;
    }

    @include media(mob) {
      font-size: var(--title-size-sm);
    }

  }

  &__slide-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    align-self: center;
    grid-area: text;
    max-width: 50rem;
    font-weight: 700;
    font-size: var(--text-size-sm);
    color: $white;

    @include media-min(tab-l) {
      max-width: 500px;
    }
  }

  &__button-wrapper {
    grid-area: button;

    @include media(mob) {
      margin-top: 1.3rem;
    }
  }

  &__button {
    @include media(mob) {
      width: 100%;
    }
  }

  &__slider-prev,
  &__slider-next {
    @include media(mob) {
      top: 1.4rem;
      transform: none;
    }
  }

  &__slider-prev {
    left: 4rem;

    @include media-min(tab-l) {
      left: 40px;
    }

    @include media(tab-l) {
      left: 1rem;
    }

    @include media(mob) {
      left: auto;
      right: 7.2rem;
    }
  }

  &__slider-next {
    right: 4rem;

    @include media-min(tab-l) {
      right: 40px;
    }

    @include media(tab-l) {
      right: 1rem;
    }

    @include media(mob) {
      left: auto;
      right: 0.4rem;
    }
  }
}
