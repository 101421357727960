/* ------- heading -------- */
.heading {
  padding: calc(var(--header-bottom-offset) + 3.8rem) 0 3.8rem;
  border-radius: $radius;
  background: $accent;

  @include media(tab-l) {
    padding: 3rem 0;
  }

  @include media(mob) {
    padding-bottom: 1.8rem;
  }

  &__row {
    display: grid;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    justify-content: space-between;
    gap: 3rem 4rem;

    @include media(mob) {
      grid-template-columns: minmax(0, 1fr);
      justify-content: flex-start;
    }
  }

  &__title {
    font-weight: 300;
    font-size: var(--title-size);
    color: $white;

    @include media(mob) {
      font-size: var(--title-size-sm);
    }

    h1 {
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;
    }

    *::selection {
      color: $accent;
      background: $white;
    }
  }

  &__breadcrumbs {
    @include media(tab-l) {
      position: relative;
      left: calc((100vw - var(--container-sm-max-width)) * 0.5);
      margin-left: auto;
      max-width: 48.5rem;
      width: 100%;
    }

    @include media(mob) {
      left: auto;
      margin-left: 0;
      max-width: none;
    }

    *::selection {
      color: $accent;
      background: $white;
    }
  }
}
