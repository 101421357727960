/* ------- UI - Attach ------- */
.ui-attach {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  gap: 2rem;

  @include media(mob-m) {
    grid-template-columns: minmax(0, 1fr);
    gap: 1.2rem;
  }

  &__box {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap: 1.6rem;

    @include media-min(tab-l) {
      gap: 16px;
    }
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: $base-color;
  }

  &__title {
    font-weight: 600;
  }

  &__button {
    position: relative;
    max-width: 20rem;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include media(mob-m) {
      width: 100%;
      max-width: none;
    }
  }

  &__button-text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 1;
  }

  &__input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		border: none;
		border-radius: $radius;
		text-indent: -9999px;
		color: transparent;
		background: none;
    pointer-events: none;

		&:focus-visible {
      outline-color: $second;
      outline-offset: 0.4rem;
		}
	}

  &__files {
    display: grid;
    gap: 1.5rem;
    justify-self: flex-start;
    grid-template-columns: 100%;
    grid-column: span 2;
    max-width: 100%;

    &:empty {
      display: none;
    }

    @include media(mob-m) {
      margin-top: 1rem;
      grid-column: auto;
    }
  }

  &__file {
    position: relative;
    max-width: 100%;
  }

  &__file-box {
    position: relative;
    padding-left: 2.4rem;
    padding-right: 3.4rem;
    width: fit-content;
  }

  &__file-icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    fill: $base-color;
    transform: translate(0, -50%);
  }

  &__file-name {
    max-width: 100%;
    font-size: var(--text-size-sm);
    font-weight: 600;
    word-wrap: break-word;
  }

  &__file-remove {
    position: absolute;
    right: 0;
    top: 50%;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.6rem;
      height: 1px;
      margin-left: -0.8rem;
      background: $base-color;
      transform: rotate(45deg);
      transition: $duration;
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background: $second;
      }
    }
  }

  &__file-error {
    margin-top: 0.5rem;
    margin-left: 2.4rem;
    grid-column: span 3;
    font-size: var(--text-size-xs);
    color: $error;
  }

  &__inputs {
    display: none;
  }

  &.is-attached {

  }
}
