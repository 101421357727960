/* ------- contacts-block -------- */
.contacts-block {
  display: grid;
  gap: 2rem;

  @include media-min(tab-l) {
    gap: 20px;
  }

  &__item {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 1rem;
  }

  &__item-icon {
    @include media-min(tab-l) {
      max-width: 24px;
      max-height: 24px;
    }

    width: 2rem;
    height: 2rem;
    fill: var(--color-primary-60);
  }

  &__item-text {
    padding-top: 0.2rem;
    font-size: var(--text-size-sm);
  }

  &__item-phone {
    text-decoration: none;

    &:hover {
      color: $accent;
    }
  }

  &__item-email {
    text-decoration: none;

    &:hover {
      color: $accent;
    }
  }
}
