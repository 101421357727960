@mixin active {
	&.is-active {
		@content;
	}
}

@mixin visible {
	&.is-visible {
		@content;
	}
}

@mixin checked {
	&.is-checked {
		@content;
	}
}

@mixin hidden {
	&[hidden],
	&.is-hidden {
		@content;
	}
}

@mixin disabled {
	&:disabled,
	&.is-disabled{
		@content;
	}
}

@mixin error {
	&.is-error,
	&.error {
		@content;
	}
}

@mixin font-face($url, $font-family, $font-weight, $font-style) {
	@font-face {
		src:
			url($url + ".woff2") format("woff2"),
			url($url + ".woff") format("woff");
		font-family: $font-family;
		font-weight: $font-weight;
		font-style: $font-style;
		font-display: swap;
	}
}

@mixin retina {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}

@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

@mixin triangle($direction: bottom, $width: 20px, $height: 20px, $color: #000) {
	width: $width;
	height: $height;

	@if $direction == left {
		border-top: calc($height / 2) solid transparent;
		border-bottom: calc($height / 2) solid transparent;
		border-right: $width solid $color;
	} @else if $direction == right {
		border-top: calc($height / 2) solid transparent;
		border-bottom: calc($height / 2) solid transparent;
		border-left: $width solid $color;
	} @else if $direction == top {
		border-left: calc($width / 2) solid transparent;
		border-right: calc($width / 2) solid transparent;
		border-bottom: $height solid $color;
	} @else if $direction == bottomLeft {
		border-bottom: $height solid $color;
		border-right: $width solid transparent;
	} @else if $direction == bottomRight {
		border-bottom: $height solid $color;
		border-left: $width solid transparent;
	} @else if $direction == topLeft {
		border-top: $height solid $color;
		border-right: $width solid transparent;
	} @else if $direction == topRight {
		border-top: $height solid $color;
		border-left: $width solid transparent;
	} @else {
		border-left: calc($width / 2) solid transparent;
		border-right: calc($width / 2) solid transparent;
		border-top: $height solid $color;
	}
}

@mixin transition($trans...) {
	transition-duration: $duration;
	transition-property: $trans;
}