/* ------- about -------- */
.p-about {
  &__tabs-menu {
    margin-bottom: 5rem;
    margin-right: -4rem;

    @include media-min(tab-l) {
      margin-bottom: 50px;
    }

    @include media(tab-l) {
      margin-bottom: 2rem;
    }
  }
}
