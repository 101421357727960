/* ------------ Lightgallery ------------ */
/*	https://github.com/sachinchoolur/lightGallery */
/* -------------------------------- */

@import "../../../node_modules/lightgallery/css/lightgallery";
@import "../../../node_modules/lightgallery/css/lg-zoom";

.lg-on {
  overflow: hidden;
  touch-action: none;
}

.lg-outer.lg-grab img.lg-object {
  border-radius: $radius;
}

.lg-outer .lg-content {
  bottom: 47px !important;
}

.lg-toolbar .lg-icon {
  color: $white;

  &:hover {
    color: rgba($white, 0.7);

    &::after {
      background: rgba($white, 0.7);
    }
  }
}

.lg-toolbar .lg-close {
  &::after {
    @include media-min(tab-l) {
      width: 20px;
      height: 20px;
    }

    content: "";
    width: 2rem;
    height: 2rem;
    mask-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_60_5108)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.2434 0.302074C18.6424 -0.0979065 19.2968 -0.101085 19.6999 0.295C20.0974 0.68572 20.1004 1.31828 19.7066 1.71266L11.4325 9.99895L19.7001 18.2543C20.0974 18.651 20.1004 19.3012 19.7068 19.7016C19.3186 20.0967 18.6901 20.0998 18.298 19.7087L10.0013 11.4322L1.74565 19.7001C1.34899 20.0973 0.698799 20.1003 0.298351 19.7068C-0.0966708 19.3185 -0.0998325 18.6901 0.291266 18.298L8.56735 10.0017L0.302075 1.75658C-0.0979066 1.35757 -0.101085 0.703171 0.295 0.30016C0.68572 -0.0973926 1.31828 -0.10042 1.71266 0.293377L9.99854 8.56707L18.2434 0.302074Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_60_5108'%3E%3Crect width='20' height='20' fill='white' transform='translate(20) rotate(90)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    mask-size: 100%;
    mask-repeat: no-repeat;
    background: $white;
    transition: $duration background;
  }
}

.lg-prev,
.lg-next {
  @include media-min(tab-l) {
    margin-top: -28px;
  }

  margin-top: -2.8rem;
  background: none;
  transition: $duration;

  &::after {
    @include media-min(tab-l) {
      width: 40px;
      height: 40px;
    }

    content: "";
    display: block;
    width: 4rem;
    height: 4rem;
    mask-size: 100%;
    mask-repeat: no-repeat;
    background: $white;
    transition: $duration background;
  }

  &:hover {
    &::after {
      background: rgba($white, 0.7);
    }
  }

  @include media(mob) {
    display: none;
  }
}

.lg-prev {
  &::after {
    mask-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_60_5081)'%3E%3Cpath d='M29.8816 39.4776C30.6474 38.7557 30.6759 37.5481 29.9559 36.7861L14.0527 19.999L29.9558 3.21387C30.6778 2.45193 30.6473 1.24618 29.8815 0.522369C29.1177 -0.201442 27.912 -0.169099 27.1882 0.594789L10.0449 18.6905C9.7097 19.0437 9.52287 19.5121 9.52295 19.999C9.52287 20.4865 9.70966 20.9556 10.0449 21.3095L27.1882 39.4052C27.912 40.1691 29.1177 40.2014 29.8816 39.4776Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_60_5081'%3E%3Crect width='40' height='40' fill='white' transform='translate(40) rotate(90)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

.lg-next {
  &::before {
    display: none;
  }

  &::after {
    mask-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_60_5082)'%3E%3Cpath d='M10.1186 0.522369C9.35283 1.2443 9.32432 2.45193 10.0443 3.21387L25.9475 20.001L10.0444 36.7861C9.32244 37.5481 9.35291 38.7538 10.1187 39.4776C10.8825 40.2014 12.0882 40.1691 12.812 39.4052L29.9553 21.3095C30.2905 20.9563 30.4773 20.4879 30.4773 20.001C30.4773 19.5135 30.2905 19.0444 29.9553 18.6905L12.812 0.594789C12.0882 -0.169099 10.8825 -0.201442 10.1186 0.522369Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_60_5082'%3E%3Crect width='40' height='40' fill='white' transform='translate(40) rotate(90)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

.lg-toolbar .lg-icon {
	transition: none;

	&::after {
		display: inline-block;
		transition: $duration;
	}
}

.lg-backdrop {
  @include media-min(tab-l) {
    backdrop-filter: blur(4px);
  }

  background: var(--color-backdrop);
  backdrop-filter: blur(0.4rem);
}
