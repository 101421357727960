/* ------- homeSection -------- */
.home-section {
  padding: 0;

  &__title {
      font-size: 3rem;
      font-weight: 700;

      @include media(mob) {
          font-size: 2rem;
      }

      h1 {
          margin: 0;
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit;
          color: inherit;
      }
  }

  &__content {
  }

  &__slider-wrapper {
    @include media(mob) {
      position: relative;
    }
  }

  &__slider {
    overflow: hidden;
    border-radius: $radius;

    @include media(mob) {
      position: static;
    }

    .swiper-pagination-bullet {
      @include media(tab-xs) {
        width: 3.2rem;
      }
    }
  }

  &__slide {
    position: relative;
    height: auto;
    min-height: 57rem;
    display: flex;

    @include media-min(tab-l) {
      height: 570px;
      min-height: unset;
    }

    @include media(tab-l) {
      min-height: 40.6rem;
    }

    @include media(mob) {
      min-height: 30.3rem;
    }

    &_1 {
      .home-section__text {
        max-width: 60.7rem;
      }
    }

    &.swiper-slide-active {
      .home-section {
        &__slide-title,
        &__slide-text,
        &__slide-button {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          position: relative;
          z-index: 10;
        }

        &__slide-title {
          transition-delay: 0.6s;
        }

        &__slide-text {
          transition-delay: 0.8s;
        }

        &__slide-button {
          transition-delay: 1s;
        }
      }
    }
  }

  &__bg-image-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(var(--color-primary-rgb));
  }

  &__bg-image {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    position: relative;
    min-height: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: flex-end;
  }

  &__slide-content {
    max-width: 73.8rem + 6rem;
    margin-left: 10rem;
    padding: calc(var(--header-bottom-offset) + 10rem) 6rem 10rem 0;

    @include media-min(tab-l) {
      max-width: 798px;
      margin-left: 100px;
      padding: 126px 60px 100px 0;
    }

    @include media(tab-l) {
      padding-top: 5rem;
      padding-bottom: 7.4rem;
      padding-right: 5rem;
    }

    @include media(tab-sm) {
      margin-left: 7rem;
    }

    @include media(tab-xs) {
      padding-right: 7rem;
      padding-bottom: 5rem;
      margin-left: 8rem;
    }

    @include media(mob) {
      margin-left: 0;
      padding-top: 3rem;
      padding-bottom: 3rem;
      padding-right: 0;
    }
  }

  &__slide-title {
    margin-bottom: 2.4rem;
    font-size: var(--title-size-lg);
    font-weight: 700;
    color: $white;
    opacity: 0;
    transition: $duration opacity, $duration * 2 transform;
    transition-delay: 0s, 0.5s;
    transform: translate3d(0, 3rem, 0);
    will-change: transform, opacity;

    @include media(mob) {
      margin-bottom: 1.6rem;
      font-size: var(--title-size-sm);
    }

    @include media-min(tab-l) {
      margin-bottom: 24px;
      transform: translate3d(0, 30px, 0);
    }
  }

  &__slide-text {
    max-width: 68rem;
    min-height: 5rem;
    font-weight: 700;
    font-size: var(--text-size-sm);
    color: $white;
    opacity: 0;
    transition: $duration opacity, $duration * 2 transform;
    transition-delay: 0s, 0.5s;
    transform: translate3d(0, 3rem, 0);
    will-change: transform, opacity;

    @include media(mob) {
      min-height: 0;
      font-size: var(--text-size-xs);
    }

    @include media-min(tab-l) {
      max-width: 680px;
      min-height: 50px;
      transform: translate3d(0, 30px, 0);
    }
  }

  &__slide-image-box {
    position: relative;
    margin-left: -9rem;
    width: 49.9rem;
    height: 54.9rem;

    @include media-min(tab-l) {
      margin-left: -90px;
      width: 499px;
      height: 549px;
    }

    @include media-between(mob, tab-xs) {
      margin-bottom: -25px;
    }

    @include media(tab-l) {
      margin-right: 7.6rem;
      width: 33.2rem;
      height: 36.6rem;
      margin-left: 0;
    }

    @include media(tab-xs) {
      margin-right: 4rem;
      margin-left: -3rem;
      width: tab-adaptive(332);
      height: 36.6rem;
      width: tab-adaptive(366);
    }

    @include media(mob) {
      display: none;
    }
  }

  &__slide-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__slide-button {
    margin-top: 4.4rem;
    opacity: 0;
    transition: $duration opacity, $duration * 2 transform;
    transition-delay: 0s, 0.5s;
    transform: translate3d(0, 3rem, 0);
    will-change: transform, opacity;

    @include media-min(tab-l) {
      margin-top: 44px;
      transform: translate3d(0, 30px, 0);
      width: 200px;
    }

    @include media(tab-l) {
      margin-top: 3.8rem;
    }

    @include media(mob) {
      margin-top: 3.2rem;
    }
  }

  &__button {
    @include media(mob) {
      width: 100%;
    }
  }

  &__slider-pagination-wrapper {
    position: absolute;
    bottom: 10rem;
    left: 0;
    width: 100%;

    @include media-min(tab-l) {
      bottom: 100px;
    }

    @include media(tab-l) {
      bottom: 7.4rem;
    }

    @include media(tab-xs) {
      bottom: 5rem;
    }

    @include media(mob) {
      bottom: auto;
      top: 100%;
      margin-top: 1.6rem;
    }
  }

  &__slider-pagination-container {
    @include media(mob) {
      display: grid;
      gap: 0.4rem;
    }
  }

  &__slider-pagination {
    position: relative;
    justify-content: flex-end;
    margin-top: 0.8rem;

    @include media-min(tab-l) {
      margin-top: 8px;
    }

    @include media(mob) {
      margin-top: 0;
      gap: 0.6rem;
      justify-content: stretch;
    }

    .swiper-pagination-bullet {
      @include media(mob) {
        width: auto;
      }
    }

    .swiper-pagination-bullet-active {
      @include media(mob) {
        background-color: var(--color-primary-dark);
      }
    }

    &.swiper-pagination {

    }
  }

  &__slider-fraction {
    position: relative;
    z-index: 10;
    width: fit-content;
    margin-left: auto;
    font-size: var(--text-size-sm);
    color: var(--color-base-60);

    @include media(mob) {
      order: 1;
      color: var(--color-base-20);
    }

    span {
      color: $white;

      @include media(mob) {
        color: var(--color-primary-dark);
      }
    }
  }

  &__slider-prev,
  &__slider-next {
    @include media(mob) {
      display: none;
    }
  }

  &__slider-prev {
    left: calc(50% - var(--container-lg-max-width) * 0.5);

    @include media(netbook) {
      left: $gutters;
    }
  }

  &__slider-next {
    right: calc(50% - var(--container-lg-max-width) * 0.5);

    @include media(netbook) {
      right: $gutters;
    }
  }
}
