/* ------- currentVacancies -------- */
.current-vacancies {
  &__title {
    margin-bottom: var(--section-title-margin);

    @include media-min(tab-l) {
      margin-bottom: 40px
    }
  }

  &__slider {
    overflow: visible;
    margin: 0;
    width: calc(var(--container-max-width) + (100vw - var(--container-max-width)) * 0.5 - 5rem);

    @include media(netbook) {
      width: auto;
    }

    .swiper-wrapper {
      @include media(mob) {
        display: grid;
        gap: 2rem;
        transform: none !important;
      }
    }
  }

  &__item {
    width: 38rem;
    height: auto;
    user-select: none;

    @include media-min(tab-l) {
      width: 380px;
    }

    @include media(tab-l) {
      width: 36rem;
    }

    @include media(tab-xs) {
      width: 34rem;
    }

    @include media(mob) {
      width: 100%;
    }

    .vacancy-item {
      &__arrow {
        margin-right: 1.6rem;

        @include media-min(tab-l) {
          margin-right: 16px;
        }

        @include media(tab-l) {
          margin-right: 0.4rem;
        }
      }

      &__link {
        @include media-min(tab-l) {
          padding-right: 10px;
          padding-bottom: 25px;
        }

        padding-right: 1rem;
        padding-bottom: 2.5rem;
      }

      &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &__more {
    display: flex;
    justify-content: flex-end;
    margin-top: calc($section-space * 0.5);

    @include media-min(tab-l) {
      margin-top: 40px
    }

    @include media(tab-l) {
      margin-top: 2rem;
    }
  }

  &__more-button {
    @include media(mob) {
      width: 100%;
    }
  }
}
