/* ------- article-item -------- */
.article-item {

  &__link {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      .article-item {
        &__content {
          border-color: var(--color-primary-light-80);
          background: var(--color-light-second);
        }
      }
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__gallery-link {
    min-height: unset;
  }

  &__image-box {
    position: relative;
    overflow: hidden;
    padding-top: 64.286%;
    border-radius: $radius $radius 0 0;
    min-height: 18rem;

    @include media-min(tab-l) {
      min-height: 180px;
    }

    @include media(mob) {
      display: none;
    }

    + .article-item {
      &__content {
        border-top-width: 0;
        border-radius: 0 0 $radius $radius;

        @include media(mob) {
          border-top-width: 1px;
          border-radius: $radius;
        }
      }
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $radius $radius 0 0;
  }

  &__content {
    padding: 1.5rem 1.6rem 2.8rem;
    flex-grow: 1;
    border: 1px solid var(--color-base-20);
    border-radius: $radius;
    transition: $duration border-color, $duration background;
    background-color: #FFFFFF;

    @include media-min(tab-l) {
      padding: 15px 16px 28px;
    }

    @include media(mob) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__head {
    display: grid;
    grid-template-columns: auto;
    justify-content: space-between;
    grid-auto-flow: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1.5rem;
  }

  &__date {
    font-family: $font-sofia;
    font-size: calc(var(--text-size-xs) + 0.1rem);
    font-weight: 500;
    color: var(--color-base-60);
  }

  &__views {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 0.5rem;
    font-family: $font-sofia;
    font-size: calc(var(--text-size-xs) + 0.1rem);
    font-weight: 500;
    color: var(--color-base-80);
  }

  &__views-icon {
    position: relative;
    top: -1px;
    width: 1.4rem;
    height: 1.4rem;
    fill: currentColor;
  }


  &__arrow {
    margin-left: auto;
    width: 2rem;
    height: 2rem;
    fill: $accent;
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1.2rem;
    font-weight: 600;
    color: var(--color-base);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__gallery-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-base);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: var(--text-size-sm);
    color: var(--color-base);
  }

  &__more {
    display: block;
    margin-top: 1.2rem;
    width: fit-content;
    text-decoration: underline;
    font-size: var(--text-size-sm);
    color: $accent;
    transition: $duration color;

    @include media(tab-l) {
      display: none;
    }

    &:hover {
      color: $second;
    }
  }
}
