/* ------------ Swiper ------------ */
/*	https://swiperjs.com/swiper-api */
/* -------------------------------- */

@import '../../../node_modules/swiper/swiper-bundle';

.swiper-button-next,
.swiper-button-prev {
	width: 40px;
	height: 40px;
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	background: none;
	transition: $duration background, $duration color, $duration border-color, $duration opacity;
	cursor: pointer;
	transform: translateY(-50%);

	&::after {
		display: none;
	}

	svg {
		position: relative;
		width: 4rem;
		height: 4rem;
		fill: currentColor;

    @include media(mob) {
      width: 2.8rem;
      height: 2.8rem;
    }
	}

	@include hover {
		color: var(--color-primary-60);
	}

	&.swiper-button-disabled {
		opacity: 0.3;
	}
}

.swiper-button-prev {
	left: 0;

	svg {
		transform: rotate(-180deg);
	}
}

.swiper-button-next {
	right: 0;
}

.swiper-pagination-bullet {
  @include media-min(tab-l) {
    width: 48px;
    height: 4px;
  }

  border-radius: 1rem;
	width: 4.8rem;
	height: 0.4rem;
	margin: 0;
	background-color: var(--color-base-60);
	opacity: 1;
	transition: $duration background-color, $duration border-color, $duration opacity, $duration transform;
	pointer-events: auto;
	will-change: transform;

  &::after {
    content: "";
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    left: 0;
    right: 0;
  }

  &:hover {
    background-color: $base-color;
  }
}

.swiper-pagination-bullet-active {
	background-color: $white;
	opacity: 1;

  &:hover {
    background: $white;
  }
}

.swiper-pagination {
	z-index: 10;
	display: grid;
  grid-auto-flow: column;
  gap: 0.8rem;
	pointer-events: none;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
	bottom: 0;

	.swiper-pagination-bullet {
		margin: 0;
	}
}
