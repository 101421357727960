/* ------- projects -------- */
.p-projects {
  &__items {
  }

  &__item {
    margin-bottom: 2rem;

    @include media-min(tab-l) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-link {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr) max-content;
    gap: 2rem;
    padding: 2rem;
    border: 1px solid var(--color-base-20);
    border-radius: $radius;
    text-decoration: none;
    transition: $duration color, $duration border-color, $duration background;

    &:hover {
      border-color: var(--color-primary-light-80);
      background: var(--color-light-second);
    }

    @include media(mob) {
      gap: 1rem;
    }
  }

  &__item-arrow {
    width: 2rem;
    height: 2rem;
    fill: $accent;
  }
}
