/* ------- UI - Input ------- */
input {
	&::-ms-clear {
		display: none;
	}
}

select,
.ui-input,
.ui-textarea {
	appearance: none;
	display: block;
	box-shadow: none;
	border: 1px solid $input-border;
	border-radius: $radius;
	outline: none;
	padding: 0 2rem;
	width: 100%;
	height: $input-height;
	font-family: $font-primary;
	font-size: var(--text-size-sm);
	text-align: left;
	color: $base-color;
	background-color: $input-background;
	transition: $duration background-color, $duration color, $duration border-color, $duration box-shadow;

  &[type="date"] {
    font-family: $font-sofia;
    font-size: calc(var(--text-size-sm) + 0.1rem);
  }

	&::placeholder {
	  color: $placeholder;
	  transition: $duration color;
	}

	&:focus {
		border-color: var(--color-base-40);
		background: var(--color-light-second);

		&::placeholder {
			color: var(--color-base-40);
		}
	}

	@include disabled {
		color: $disabled-color;
		background-color: $disabled-background;
	}

	@include error {
		// color: $error;
		border-color: $error;
    background: var(--color-light-second);

    &::placeholder {
      // color: $error;
    }
	}
}


.ui-textarea {
	padding-top: 2.2rem;
	padding-bottom: 2.2rem;
	height: auto;
  min-height: 14rem;
	resize: none;

  @include media-min(1025) {
    scrollbar-width: thin;
    scrollbar-color: rgba($black, 0.3);

    &::-webkit-scrollbar {
      position: relative;
      width: 1rem;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      border-left: 0.4rem solid var(--color-light-second);
      border-right: 0.4rem solid var(--color-light-second);
      background: rgba($black, 0.3);
      transition: $duration background;
    }

    &::-webkit-scrollbar-track {
      margin: 0.5rem 0;
      background: transparent;
    }
  }
}

.ui-input-phone {
}
