/* ------- Modal -------- */
.modal {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 200;
	display: none;
	width: 100%;
	height: calc(var(--vh, 1%) * 100);
	overflow: hidden;
  overflow-y: auto;
	opacity: 0;
	background: $backdrop;
	transition: $duration opacity;
  backdrop-filter: blur(4px);

	&__close {
		position: absolute;
		top: -8rem;
		right: -12rem;
		z-index: 20;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6rem;
		height: 6rem;
    color: $white;
		background: transparent;
    transition: $duration color;

		&:hover,
		&:active {
			color: rgba($white, 0.7);
		}

    @include media(tab-l) {
      right: -2rem;
    }
	}

	&__close-icon {
		display: block;
		width: 2rem;
		height: 2rem;
		fill: currentColor;
		transition: $duration fill;
	}

	&__wrap {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-grow: 0;
		margin: auto;
		padding: 8.6rem 12rem;
		width: 100%;
		min-height: 100%;

    @include media(tab-l) {
      padding-left: $gutters;
      padding-right: $gutters;
    }

    @include media(mob) {
      padding-bottom: 4rem;
    }
	}

	&__window {
		position: relative;
		flex-shrink: 0;
		flex-grow: 0;
		padding: 3.5rem 10rem;
    border-radius: $radius;
		width: 98rem;
		max-width: 100%;
		background: $white;
		transform: scale(0.8);
		transition: $duration transform;
		will-change: transform;

    @include media(tab-l) {
      width: 70rem;
      padding-bottom: 4rem;
      padding-top: 4rem;
    }

		@include media(mob) {
      width: 100%;
      padding-left: $gutters;
      padding-right: $gutters;
      padding-bottom: 3rem;
		}
	}

	&__title {
		margin: 0 0 4rem;
    font-size: var(--title-size-sm);
		font-weight: 700;

    @include media(tab-l) {
      margin-bottom: 3rem;
    }

    @include media(mob) {
      margin-bottom: 2rem;
    }
	}

	&.is-visible {
		display: block;
	}

	&.is-active {
		opacity: 1;
		transition: $duration;

		.modal__window {
			transform: scale(1);
		}
	}
}

.is-modal-active {
	overflow: hidden;
  padding-right: var(--modal-lock-padding-right);
	touch-action: none;
}
