/* ------- Fonts ------- */

@include font-face("../fonts/Raleway-Light", "Raleway", 300, normal);
@include font-face("../fonts/Raleway-Regular", "Raleway", 400, normal);
@include font-face("../fonts/Raleway-Medium", "Raleway", 500, normal);
@include font-face("../fonts/Raleway-SemiBold", "Raleway", 600, normal);
@include font-face("../fonts/Raleway-Bold", "Raleway", 700, normal);
@include font-face("../fonts/SofiaSans-Regular", "Sofia Sans", 400, normal);
@include font-face("../fonts/SofiaSans-SemiBold", "Sofia Sans", 600, normal);
@include font-face("../fonts/SofiaSans-Bold", "Sofia Sans", 700, normal);
