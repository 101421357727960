/* ------- project-single -------- */
.p-project-single {

  &__row {
    display: grid;
    gap: 2rem;

    @include media-min(tab-l) {
      gap: 20px;
    }

    @include grid-columns(2);

    @include media(mob) {
      gap: 0;

      @include grid-columns(1);
    }
  }

  &__column {
  }

  &__contacts {
    margin-top: 6rem;

    @include media-min(tab-l) {
      margin-top: 60px;
    }

    @include media(tab-l) {
      margin-top: 4rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__contacts-title {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: var(--text-size);

    @include media-min(tab-l) {
      margin-bottom: 20px;
    }
  }

  &__description {
    h2 {
      @include media(tab-l) {
        margin-bottom: 2rem;
      }
    }
  }

  &__slider-wrapper {
    @include media(tab-l) {
      margin-right: calc((100vw - var(--container-sm-max-width)) * -0.5);
    }

    @include media(mob) {
      display: none;
    }
  }

  &__slider-mobile {
    margin-bottom: 2rem;

    @include media-min(mob) {
      display: none;
    }
  }
}
