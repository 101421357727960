/* ------- utilites ------- */
.u-text-highlighted {
    color: $accent;
}

.u-style-trust {
  background: var(--color-trust);
}

.u-style-barentsburg {
  background: var(--color-barentsburg);
}

.u-style-tourism {
  background: var(--color-tourism);
}

.u-style-murmansk {
  background: var(--color-murmansk);
}

.trap-input {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
}

.is-hidden {
  visibility: hidden;
}
