/* ------- news -------- */
.p-news {

  &__row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 28rem;
    gap: 4rem 2rem;

    @include media(tab-l) {
      grid-template-columns: minmax(0, 1fr);
    }

    @include media(mob) {
      gap: 3rem;
    }
  }

  &__column {
    &_right {
      display: flex;
      flex-direction: column;
    }
  }

  &__items {
    display: grid;
    gap: 2rem;

    @include media-min(tab-l) {
      gap: 20px;
    }

    @include grid-columns(3);

    @include media(tab-l) {
      @include grid-columns(2);
    }

    @include media(mob) {
      @include grid-columns(1);
    }
  }

  &__item {
    .article-item__image-box {
      @include media(mob) {
        display: block;
      }
    }
  }

  &__calendar {
    @include media(tab-l) {
      display: none;
    }
  }

  &__pagination {
    margin-top: 4rem;

    @include media-min(tab-l) {
      margin-top: 40px;
    }

    @include media(tab-l) {
      margin-top: 3rem;
    }
  }
}

