/* ------- Footer ------- */
.footer {
  flex-shrink: 0;
  width: 100%;
  color: $white;
  background: $base-color;

  @include media(tab) {
    padding-bottom: 0;
  }

  &__logo-dark-image {
    display: none !important;
  }

  &__section {
    padding: 3rem 0;
    border-bottom: 2px solid var(--color-base-80);

    &_first {
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;

      @include media(tab) {
        display: none;
      }
    }

    &_second {
      @include media(tab) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      @include media(mob) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }

    &_third {
      display: grid;
      align-items: center;
      grid-template-columns: calc(var(--logo-width) + 3rem) minmax(0, 59.5fr) minmax(0, 40.5fr) max-content;
      grid-template-areas: "logo description contacts registry";
      gap: 3rem 4rem;

      @include media(pc) {
        grid-template-columns: calc(var(--logo-width)) minmax(0, 59.5fr) minmax(0, 40.5fr) max-content;
      }

      @include media(tab) {
        grid-template-areas:
                "contacts logo registry"
                "description description description";
        grid-template-columns: minmax(0, 1fr) calc(var(--logo-width)) minmax(0, 1fr);
        align-items: center;
      }

      @include media(tab-sm) {
        grid-template-areas:
                "logo"
                "contacts"
                "registry"
                "description";
        grid-template-columns: minmax(0, 1fr);
        justify-content: center;
        gap: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 4rem;
      }
    }

    &_fourth {
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
      display: flex;
      justify-content: right;

      @include media(tab-sm) {
        justify-content: center;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__copyright-studio {
    font-size: var(--text-size-sm);
    color: var(--color-base-20);
    font-weight: 600;
    display: flex;

    @include media(mob) {
      font-size: var(--text-size-xs);
    }

    &_15web {
      position: relative;
      margin-left: 0.5rem;
      padding-left: 2.4rem;
      text-decoration: none;

      @include media(mob) {
        padding-left: 2.1rem;
      }

      &:hover {
        color: $white;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370.9 254.9' fill='%23d60d35'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M132.3 0h95.6L95.5 254.9H0L132.3 0m143 0h95.6L238.5 254.9H143L275.3 0'/%3E%3C/svg%3E") no-repeat 50%/contain;
        height: 1.5rem;
        width: 2.2rem;

        @include media(mob) {
          height: 1.4rem;
          width: 1.9rem;
        }
      }
    }
  }

  &__menu {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    gap: 2rem;
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 500;
    font-size: var(--text-size-sm);
    color: var(--color-base-20);
  }

  &__menu-item {

    &_active {
      .footer__menu-link {
        color: var(--color-base-60);
      }
    }
  }

  &__menu-link {
    display: block;
    text-decoration: none;
    text-wrap: nowrap;

    &:hover {
      color: var(--color-base-60);
    }
  }

  &__subscribe {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    align-items: center;
    gap: 6rem;

    @include media-min(tab-l) {
      gap: 60px;
    }

    @include media(tab-sm) {
      gap: 4rem;
    }

    @include media(mob) {
      gap: 1.6rem;
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__subscribe-title {
    margin: 0;
    font-weight: 300;
    font-size: 2.4rem;

    @include media(pc) {
      font-size: 2rem;
    }

    @include media(tab) {
      font-size: 1.6rem;
    }
  }

  &__subscribe-form {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    justify-content: flex-end;
    gap: 2rem 6rem;

    @include media-min(tab-l) {
      gap: 20px 60px;
    }

    @include media(tab) {
      gap: 2rem;
    }

    @include media(mob-m) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  &__subscribe-input,
  &__subscribed {
    max-width: 40rem;
    margin-left: auto;
  }

  &__subscribed {
    margin-top: 0.5rem;
    font-size: 1.25rem;
  }

  &__subscribe-input {
    height: 4rem;
    border: 1px solid var(--color-base-80);
    color: $white;
    background: var(--color-base-80);

    &::placeholder {
      color: var(--color-base-40);
    }

    &:focus {
      background: var(--color-base-80);
    }

    @include media(mob) {
      max-width: none;
    }
  }

  &__subscribe-submit {
    min-width: 22rem;

    @include media(tab-sm) {
      min-width: 18rem;
    }

    @include media(mob-m) {
      width: 100%;
    }
  }

  &__logo-wrapper {
    grid-area: logo;
    padding-right: 3rem;

    @include media(pc) {
      padding-right: 0;
    }

    @include media(tab-sm) {
      display: flex;
      justify-content: center;
    }
  }

  &__logo-image {
    display: block;
    width: $logo-width;
    height: $logo-height;
    max-width: 240px;
    max-height: 59px;
  }

  &__description {
    grid-area: description;
    max-width: 254px;
    font-size: var(--text-size-xs);
    font-weight: 500;

    @include media(tab) {
      max-width: none;
      text-align: center;
    }

    @include media(tab-sm) {
      text-align: center;
    }
  }

  &__description-year {
    font-family: $font-sofia;
    font-size: calc(var(--text-size-xs) + 0.1rem);
  }

  &__contacts {
    display: grid;
    justify-content: flex-start;
    grid-area: contacts;
    gap: 0.7rem 2.4rem;
    padding-bottom: 0.4rem;

    @include media(tab) {
      grid-auto-flow: column;
      padding-bottom: 0;
    }

    @include media(tab-sm) {
      grid-auto-flow: column;
      margin-left: 0;
      column-gap: 3rem;
      justify-content: center;
    }
  }

  &__contacts-phone,
  &__contacts-email {
    font-weight: 700;
    font-size: var(--text-size-sm);
    text-decoration: none;
    color: var(--color-base-20);
    white-space: nowrap;

    &:hover {
      color: $white;
    }
  }

  &__contacts-phone {
    font-family: $font-sofia;
    font-size: calc(var(--text-size-sm) + 0.1rem);
  }

  &__registry {
    display: flex;
    align-items: flex-end;
    grid-area: registry;
    margin-left: auto;
    color: var(--color-base-20);

    @include media(tab) {
      align-items: center;
    }

    @media (max-width: 820px) {
      max-width: 19rem;
    }

    @include media(tab-sm) {
      max-width: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__registry-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 5.8rem;
    margin-right: 1.6rem;

    @include media-min(tab-l) {
      height: 58px;
    }

    @include media(tab) {
      height: 4.6rem;
    }
  }

  &__registry-logo-text {
    font-size: 9.916px;
    font-weight: 700;
    color: $white;
  }

  &__registry-content {
    position: relative;
    padding-left: 1.6rem;


    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 1px;
      height: 4rem;
      margin-top: -2rem;
      background: var(--color-base-40);
    }
  }

  &__registry-title {
    margin-bottom: 0.2rem;
    font-weight: 700;
    font-size: var(--text-size-sm);

    @include media(tab) {
      font-size: var(--text-size-xs);
    }
  }

  &__registry-text {
    font-weight: 500;
    font-size: var(--text-size-xs);

    span {
      font-family: $font-sofia;
      font-size: calc(var(--text-size-xs) + 0.1rem);
    }
  }
}
