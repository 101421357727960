/* ------- Field Box ------- */
.is-label-error {
  display: none;
}

.field-box {
  position: relative;

  &__clear {
    position: absolute;
    top: calc($input-height * 0.5);
    right: 2rem;
    display: none;
    margin-top: -0.8rem;
    width: 1.6rem;
    height: 1.6rem;
    mask-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.70698 1.79289C1.31646 2.18342 1.31646 2.81658 1.70698 3.20711L6.65672 8.15685L1.70696 13.1066C1.31643 13.4971 1.31643 14.1303 1.70696 14.5208C2.09748 14.9114 2.73065 14.9114 3.12117 14.5208L8.07094 9.57106L13.0207 14.5208C13.4112 14.9113 14.0444 14.9113 14.4349 14.5208C14.8254 14.1303 14.8254 13.4971 14.4349 13.1066L9.48515 8.15685L14.4349 3.20712C14.8254 2.8166 14.8254 2.18343 14.4349 1.79291C14.0444 1.40238 13.4112 1.40238 13.0207 1.79291L8.07094 6.74263L3.1212 1.79289C2.73067 1.40237 2.09751 1.40237 1.70698 1.79289Z' fill='%23AAA8A7'/%3E%3C/svg%3E%0A");
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: var(--color-base-40);
    transition: $duration background-color;
    cursor: pointer;

    &:hover {
      background-color: $base-color;
    }
  }

  &__input {
    padding-right: 5rem;
    background-position: right 2rem center;
    background-repeat: no-repeat;

    &:required {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_141_12438)'%3E%3Cpath d='M15.6753 12.0187L10.017 1.60193C9.58989 0.882988 8.83564 0.453613 7.99939 0.453613C7.16314 0.453613 6.40889 0.882988 5.98183 1.60193C5.97861 1.60743 5.97589 1.61293 5.97267 1.61843L0.333169 12.0023C-0.103049 12.7364 -0.111299 13.618 0.310701 14.3603C0.73367 15.1031 1.49567 15.5462 2.35026 15.5462H13.6173C14.4719 15.5462 15.2652 15.1031 15.6881 14.3603C16.1101 13.618 16.1019 12.7363 15.6753 12.0187ZM7.06095 5.22327C7.06095 4.70496 7.48111 4.28483 7.99939 4.28483C8.5177 4.28483 8.93783 4.70499 8.93783 5.22327V8.97705C8.93783 9.4953 8.51767 9.91549 7.99939 9.91549C7.48111 9.91549 7.06095 9.49527 7.06095 8.97705V5.22327ZM7.99939 13.6693C7.22317 13.6693 6.5917 13.0379 6.5917 12.2616C6.5917 11.4854 7.22314 10.854 7.99939 10.854C8.77561 10.854 9.40704 11.4854 9.40704 12.2616C9.40708 13.0378 8.77564 13.6693 7.99939 13.6693Z' fill='%236396C5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_141_12438'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-position: right 2rem top 2.1rem;
      background-repeat: no-repeat;
    }

    &.is-error {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_141_6704)'%3E%3Cpath d='M15.6753 12.5187L10.017 2.10193C9.58989 1.38299 8.83564 0.953613 7.99939 0.953613C7.16314 0.953613 6.40889 1.38299 5.98183 2.10193C5.97861 2.10743 5.97589 2.11293 5.97267 2.11843L0.333169 12.5023C-0.103049 13.2364 -0.111299 14.118 0.310701 14.8603C0.73367 15.6031 1.49567 16.0462 2.35026 16.0462H13.6173C14.4719 16.0462 15.2652 15.6031 15.6881 14.8603C16.1101 14.118 16.1019 13.2363 15.6753 12.5187ZM7.06095 5.72327C7.06095 5.20496 7.48111 4.78483 7.99939 4.78483C8.5177 4.78483 8.93783 5.20499 8.93783 5.72327V9.47705C8.93783 9.9953 8.51767 10.4155 7.99939 10.4155C7.48111 10.4155 7.06095 9.99527 7.06095 9.47705V5.72327ZM7.99939 14.1693C7.22317 14.1693 6.5917 13.5379 6.5917 12.7616C6.5917 11.9854 7.22314 11.354 7.99939 11.354C8.77561 11.354 9.40704 11.9854 9.40704 12.7616C9.40708 13.5378 8.77564 14.1693 7.99939 14.1693Z' fill='%23E63329'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_141_6704'%3E%3Crect width='16' height='16' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-position: right 2rem center;
      background-repeat: no-repeat;

      ~ .is-label-error {
        display: block;
      }
    }

    &.is-error-no-message {
      ~ .is-label-error {
        display: none;
      }
    }
  }

  &.is-filed {
    .field-box {
      &__input {
        border-color: var(--color-base-40);
        background-color: var(--color-light-second);

        &:not(.is-error) {
          background-image: none;
        }

        &.is-error {
          border-color: $error;
          padding-right: 9rem;
          background-position: right 5.6rem center;
        }

        &.ui-input-phone {
          font-family: $font-sofia;
          font-size: calc(var(--text-size-sm) + 0.1rem);
        }
      }

      &__clear {
        display: block;
      }
    }

    &.is-focused {
      .field-box {
        &__input {
          border-color: var(--color-primary-light-80);

          &.is-error {
            border-color: $error;
          }
        }

        &__clear {
          background-color: $base-color;
        }
      }
    }
  }

  &.is-error {
    .is-label-error {
      display: block;
    }
  }

  &_date {
    &.is-filed {
      .field-box__input {
        font-family: $font-sofia;
      }
    }
  }
}
